

const Footer = (props) => {


    return <div className="fixed bottom-0 left-0 w-screen bg-white flex flex-row justify-end z-50" >

        <div
            style={{
                fontFamily: 'pixel',
                fontSize: '0.2em',
                color: '#2E3192',

            }}
            className=" pr-5 space-x-5"
        >
            <a
                target="_blank"
                rel="noreferrer"
                href="https://opensea.io/collection/creaturecrowd"
            >Opensea</a>

            <a
                target="_blank"
                rel="noreferrer"
                href="https://www.twitter.com/creatureworld"
            >Twitter</a>

            <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/creature.world"
            >Instagram</a>
        </div>

    </div>
}

export default Footer