import { useRef } from "react";
import { useOutsideAlerter } from "../../helpers/OutsideAlerter";
import { config } from "../../config";

import phase1 from "../../assets/phaseDiagrams/guide/Phase1_Blue.png"
import phase2 from "../../assets/phaseDiagrams/guide/Phase2_Blue.png"
import phase3 from "../../assets/phaseDiagrams/guide/Phase3.png"

const Overview = ({ close }) => {

    let ref = useRef(null);

    useOutsideAlerter(ref, close)

    return <div className='fixed top-0 left-0 w-screen flex flex-col justify-center items-center bg-opacity-75 text-white min-h-screen bg-white  z-50'>
        {/* DESKTOP */}
        <div className=' flex-col justify-center'>
            <div
                ref={ref}
                style={{
                    // minWidth: "500px",
                    maxWidth: "800px",
                    maxHeight: "80vh",
                    width: "100%",
                    border: '0.1em solid #2E3192',
                }}

                className=" w-full bg-white"
            >
                <div
                    style={{
                        fontSize: '3rem',
                        borderBottom: '0.2em solid #2E3192',
                    }}
                    className="flex flex-row">
                    <div
                        style={{
                            borderRight: '0.2em solid #2E3192',
                        }}
                        className="px-5 group"
                    >
                        <button
                            onClick={() => {
                                close()
                            }
                            }
                            style={{
                                lineHeight: "1.8em",
                                color: '#2E3192'
                            }}
                            className=" group-hover:text-red-500 hover:text-red-500"
                        >
                            X
                        </button>
                    </div>
                    <div className="w-full flex flex-row justify-center">
                        <p
                            style={{
                                lineHeight: "1.8em",
                                color: '#2E3192'
                            }}
                        >GUIDE</p>
                    </div>
                </div>

                <div
                    style={{
                        fontSize: '1.75rem',
                        color: '#2E3192',
                        lineHeight: "1.25em",
                        height: "50vh"
                    }}
                    className=" p-5 space-y-5 overflow-y-scroll scrollbar-none h-full flex flex-col">
                    <p>
                        Crowd: Chapter 2
                    </p>
                    <p>
                        In Chapter 2, Crowds will undergo a series of evolutions and changes, unlocking new artwork and diversifying the collection.
                        There are three ways to participate in Chapter 2, called phases - you may participate in one of them, all of them, or any combination of the three.
                        Each phase is separate from the others - participation in the first is not required for the second and/or third.
                    </p>
                    <p>
                        Phase 1 - The Crowd Passage
                    </p>

                    <p>
                        Burn one Crowd to evolve another
                    </p>
                    <img src={phase1} alt="phase1" />
                    <p>
                        When you evolve a Crowd, its original traits remain the same.
                        A new trait will be added to reflect the type of evolution it undergoes.
                    </p>

                    {config.phase >= 2 && <>
                        <p>
                            Phase 2 - The Crowd Flush
                        </p>
                        <p>
                            Burn every “number of colors” in a palette to receive a digital and physical Reach artwork
                        </p>
                        <img src={phase2} alt="phase2" />
                        <p>
                            The Reach artwork you receive will feature the palette of the Crowds you burned
                            The specific Crowd you choose to evolve will have no effect on the resulting artwork, only its token ID will be carried through the evolution
                        </p>
                    </>}

                    {config.phase >= 3 && <>
                        <p>
                            Phase 3 - The Full Set
                        </p>
                        <img src={phase3} alt="phase3" />

                        <p>
                            Burn one Crowd from every color palette to receive a digital and 3ft x 3ft physical Grid artwork
                        </p>

                        <p>
                            The Grid artwork you receive will be a combination of all the colors from the original Crowd collection, unique to you
                            The specific Crowd you choose to evolve will have no effect on the resulting artwork, only its token ID will be carried through the evolution
                        </p>
                    </>}




                </div>
            </div>
        </div>


    </div>
}

export default Overview;