import React, { useEffect, useRef } from 'react';
import { select } from 'd3';
import './FloatingCircles.css';

const FloatingCircles = () => {
  const svgRef = useRef();

  const createParticles = (numParticles) => {
    return Array.from({ length: numParticles }, () => ({
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      speedX: (Math.random() * 2 - 1) * 0.5,
      speedY: (Math.random() * 2 - 1) * 0.5,
      size: Math.random() * 6 + 1,
      opacity: Math.random() * 0.5 + 0.2,
    }));
  };

  useEffect(() => {
    const svg = select(svgRef.current);
    const numParticles = 50;
    const particles = createParticles(numParticles);

    svg
      .attr('width', window.innerWidth)
      .attr('height', window.innerHeight);

    const pixels = svg
      .selectAll('rect')
      .data(particles)
      .join('rect')
      .attr('width', (d) => d.size)
      .attr('height', (d) => d.size)
      .attr('fill', (d) => `rgba(255, 255, 255, ${d.opacity})`);

    const updateParticles = () => {
      particles.forEach((particle) => {

        const speed = Math.sqrt(particle.speedX ** 2 + particle.speedY ** 2);
        const minSpeed = 0.25;
        if (speed < minSpeed) {
          particle.speedX = (particle.speedX / speed) * minSpeed;
          particle.speedY = (particle.speedY / speed) * minSpeed;
        }

        particle.x += particle.speedX;
        particle.y += particle.speedY;

        if (particle.x < 0) particle.x = window.innerWidth;
        if (particle.x > window.innerWidth) particle.x = 0;
        if (particle.y < 0) particle.y = window.innerHeight;
        if (particle.y > window.innerHeight) particle.y = 0;
      });

      pixels
        .data(particles)
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y);

      requestAnimationFrame(updateParticles);
    };

    updateParticles();

    return () => {
      // Clean up event listeners if needed
    };
  }, []);
  return <svg ref={svgRef}></svg>;
};

export default FloatingCircles;