import Footer from "../layout/Footer"
import { useWindowSize } from "../helpers/WindowSize"
import Intro from "../components/Intro"
import FloatingCircles from "../components/FloatingCircles"
import ImageStack from "../components/ImageStack"
import Splash from "../components/Splash"


const Burn = (props) => {
    const size = useWindowSize()
    // get the size the screen items are based off of
    const getSize = () => {

        let multiplier = 8
        let max = 125
        let min = 75

        let overall = (Math.ceil((size.width * size.height) * 0.00001 * multiplier))

        if (overall > max) {
            overall = max
        } else if (overall < min) {
            overall = min
        }
        overall = overall.toString() + "px"
        return overall
    }
    const overall = getSize()

    let examples = ['https://bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi.ipfs.dweb.link/104.png',
        'https://bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi.ipfs.dweb.link/105.png',
        'https://bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi.ipfs.dweb.link/104.png']

    return <div
        style={{
            fontSize: overall,
            fontFamily: "pixel",
            color: "#f2f2f2"
        }}
    >
        <Splash />

        <Intro />
        <FloatingCircles />

        <Footer />
    </div>
}

export default Burn