
import { SpeakerWaveIcon } from '@heroicons/react/24/solid'
import { useNetwork, useSwitchNetwork } from 'wagmi'
import { config } from '../config'
import { useUserStore } from '../stores/UserStore'

const NetworkWarning = (props) => {
  const { chain } = useNetwork()
  const { switchNetwork } =
    useSwitchNetwork()
  const { user } = useUserStore()

  return <>
    {user.loggedIn && chain && chain.id !== config.network && <div className='absolute z-50 top-0 left-0 w-full h-full'>
      {user.loggedIn && chain && chain.id !== config.network ? <div style={{ width: "100%" }} className="z-50 absolute top-0 left-0 mb-5 h-full flex justify-center font-mono">
        <button onClick={() => {
          switchNetwork(config.network)
        }} className="flex relative  flex-row items-center justify-center w-full bg-red-500 text-white z-50 h-12 " style={{ maxWidth: "1100px" }}>
          <div className="flex items-center space-x-2 flex-row">
            {/* <div className="p-2 bg-header-font rounded-lg bg-opacity-40">
              <SpeakerWaveIcon className="h-5" />
            </div> */}
            <div className="flex flex-col sm:flex-row">
              <p className="text-xs md:text-sm">Please click to switch to {config.network === 1 ? "Ethereum Mainnet" : "Georli Test Network"}.</p>

            </div>


          </div>

        </button>
        <div className='fixed w-screen h-full bg-black bg-opacity-50  z-30'>

        </div>
      </div> : null}
    </div>}
  </>
}

export default NetworkWarning