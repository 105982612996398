import { useNavigate } from "react-router-dom";
import graphic from "../assets/landingGraphic.png"
import { useTransitionStore } from "../stores/TransitionStore";
import TickerTape from "./TickerTape";
import Countdown from "./Countdown";
import arrow from "../assets/arrow.svg"
import phase1Graphic from "../assets/phaseDiagrams/Phase 1.png"
import phase2Graphic from "../assets/phaseDiagrams/Phase3.png"

import logo from "../assets/Logo.png"
import { useState } from "react";

import { config } from "../config.js"

const Intro = () => {
    const targetDate = new Date(config.siteOpenDate);
    const [isPast, setIsPast] = useState(false);


    const navigate = useNavigate();

    return (
        <div
            className="flex flex-col-reverse overflow-x-hidden lg:flex-row  min-h-screen w-screen justify-center items-center"

        >
            <TickerTape />


            {/* Text DESKTOP */}
            <div
                style={{
                    fontSize: '0.5em',
                }}
                className={`text-left
                    mx-20 pb-20 
                    flex-col items-left
                    hidden lg:flex
                `}
            >

                <img src={logo} alt="logo" style={{
                    width: '6em',
                }} />
                <div
                    style={{
                        fontSize: '0.4em',
                        width: '15em',
                        lineHeight: '1em',
                    }}
                    className="space-y-5 leading-5 mt-5"
                >
                    <p

                    >
                        In Chapter2, Crowds will undergo a series of evolutions and changes, unlocking new artwork and diversifying the collection
                    </p>
                    <p>
                        There are three ways to participate in Chapter 2, called <span
                            style={{
                                color: '#f3d650',
                            }}
                        >phases</span> - you may participate in one of them, all of them, or any combination of the three
                    </p>
                    {!isPast && <>
                        <p>
                            The burn begins in...
                        </p>
                        <Countdown targetDate={targetDate} setPast={() => setIsPast(true)} />
                    </>}

                </div>
                {isPast && <div>
                    <button
                        onClick={() => {
                            navigate('/burn')

                        }
                        }
                        style={{
                            color: '#2E3192',
                            fontSize: "0.65em"
                            // background: "#f2f2f2"
                        }}
                        className="bg-white px-5 py-2 rounded-3xl mt-4"
                    >
                        ENTER BURN
                    </button>
                </div>}


            </div>

            {/* Text Mobile */}
            <div
                style={{
                    fontSize: '0.5em',
                }}
                className={`text-left
                    mx-20 py-10
                    flex flex-col justify-center h-full 
                    lg:hidden
                `}
            >
                <img src={logo} alt="logo" style={{
                    width: '8em',
                }} />
                <div
                    style={{
                        fontSize: '0.55em',
                        width: '15em',
                        lineHeight: '1em',
                    }}
                    className="space-y-2 leading-5 mt-5"
                >
                    <p

                    >
                        In Chapter2, Crowds will undergo a series of evolutions and changes, unlocking new artwork and diversifying the collection
                    </p>
                    <div
                        className="flex flex-col items-center justify-start w-full"
                    >
                     
                        <img
                            style={{
                                width: '18em',
                            }}
                            src={phase2Graphic} alt="graphic" className=" " />

                    </div>

                    <p>
                        There are three ways to participate in Chapter 2, called <span
                            style={{
                                color: '#f3d650',
                            }}
                        >phases</span> - you may participate in one of them, all of them, or any combination of the three
                    </p>


                    {!isPast && <>
                        <p>
                            The burn begins in...
                        </p>
                        <Countdown targetDate={targetDate} setPast={() => setIsPast(true)} />
                    </>}
                </div>

                {isPast && <div>
                    <button
                        onClick={() => {
                            navigate('/burn')

                        }
                        }
                        style={{
                            color: '#2E3192',
                            fontSize: "1em"
                            // background: "#f2f2f2"
                        }}
                        className="bg-white px-5 py-2 rounded-3xl mt-4"
                    >
                        ENTER BURN
                    </button>
                </div>}


            </div>
            <div className="lg:flex flex-col hidden  justify-center items-center">
              
                <img
                    style={{
                        width: '6.6em',
                    }}
                    src={phase2Graphic} alt="graphic" className="px-10 " />

            </div>


            <div className="">
                <p
                    style={{
                        fontSize: '0.35em',
                    }}
                    className="invisible  lg:hidden"
                >SPACER</p>
            </div>
        </div>
    );
}

export default Intro