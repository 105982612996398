import { useEffect, useCallback, useState, useRef } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrop, useDrag } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';
import { config } from '../config';
import Dropdown from "./Dropdown";
import Phase3 from "./Phase3";

const ResultBox = () => {

    return <div
        className={`bg-white flex-shrink-0 relative shadow flex flex-col justify-center items-center `} // Add the square class here
        style={{
            width: '1em',
            height: '1em',
            margin: '0.1em',
            color: "#2E3192"
        }}
    >
        <p
            style={{
                lineHeight: '1em',
                fontSize: '1em',
            }}
        >?</p>

    </div>
}


const useCombinedRefs = (...refs) => {
    const targetRef = useRef(null);

    useEffect(() => {
        refs.forEach((ref) => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
};



const List = ({ title, list, addItem, removeItem, sourceList, hasControls, itemLimit, children, onDrop, columns, syncScroll }) => {
    const [{ isOver }, drop] = useDrop({
        accept: 'item',
        drop: (item) => onDrop(item),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const combinedRef = useCombinedRefs(drop, syncScroll);

    return (
        <div className="text-center">

            <div className="flex flex-row justify-center">
                {hasControls && (
                    <button
                        style={{
                            color: "#2E3192",
                            backgroundColor: 'white',
                        }}
                        onClick={() => {
                            removeItem()
                        }}
                        className=" rounded-l-xl  h-full flex flex-col justify-center items-center">
                        -
                    </button>
                )}

                <div
                    // ref={drop}
                    ref={combinedRef}
                    style={{
                        minWidth: '1em',
                        width: '100vw',
                        maxWidth: itemLimit ? `${itemLimit * 17}vw` : '75vw',
                        maxHeight: `${columns === 'single' ? '1.5em' : '3em'}`,
                        height: "100vh",
                        padding: '0.1em'
                    }}

                    className={` ${!hasControls ? 'rounded-xl' : ''} ${columns === 'single' ? 'overflow-x-scroll flex-row' : 'overflow-y-scroll flex-wrap justify-center '} scrollbar-none
                    ${itemLimit < 5 ? ' justify-center  ' : ''}
                    border-2 border-white  flex  content-start`} // Add the grid class here
                >
                    {list.map((item) => {
                        let url = ''
                        let parsedId = parseInt(item.id)
                        let phase = 0
                        if (parsedId >= 0) {
                            let isEvolved = item.metadata?.attributes?.some((attribute) => { return attribute.trait_type === "EVOLUTION" })
                            if (isEvolved) {
                                let phaseAttribute = item.metadata.attributes.find((attribute) => { return attribute.trait_type === "EVOLUTION" })
                                if (phaseAttribute.value === 'Grid') {
                                    phase = 3
                                } else if (phaseAttribute.value === 'Reach') {
                                    phase = 2
                                } else {
                                    phase = 1
                                }

                                url = item.metadata.image
                            } else {
                                url = `https://bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi.ipfs.dweb.link/${item.id}.png`
                            }
                        }

                        return (
                            <Item key={item.id} phase={phase} metadata={item?.metadata} name={item.id} url={url} {...item} sourceList={sourceList} />
                        )
                    })}
                </div>
                {hasControls && (
                    <button
                        style={{
                            color: "#2E3192",
                            backgroundColor: 'white'
                        }}
                        onClick={() => {
                            addItem()
                        }}

                        className=" rounded-r-xl  h-full flex flex-col justify-center items-center">
                        +
                    </button>
                )}
            </div>

            <div className="w-full flex items-center justify-center">
                <p
                    style={{
                        lineHeight: '1em',
                        fontSize: '0.25em',
                        color: "#2E3192"
                    }}
                    className="bg-white rounded-b-lg  px-2"
                >{title}</p>
            </div>

        </div>

    );
};

const Item = ({ id, name, url, listId, metadata, index, phase, className, style, onClick, onMouseEnter, onMouseLeave, sourceList }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'item',
        item: { id, name, url, metadata, listId, index, sourceList },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsImageLoaded(true);
    };
    return (<div>
        {parseInt(id) < 0 ?
            <div
                className={`  
                ${className} relative  flex-shrink-0
                `} // Add the square class here
                style={{
                    ...style,
                    width: '1em',
                    height: '1em',
                    margin: '0.1em',
                }}
            ></div> :
            <div
                ref={drag}
                className={`  relative shadow ${isDragging ? 'opacity-50' : ''
                    } ${className}  flex-shrink-0 `} // Add the square class here
                style={{
                    ...style,
                    width: '1em',
                    height: '1em',
                    margin: '0.1em',
                }}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >

                {phase > 0 && (
                    <p
                        style={{
                            fontSize: '0.12em',
                            background: "#FBD34C",
                            color: '#2E3192'
                        }}
                        className="text-center text-white z-30 absolute top-0 left-0  px-1 rounded-br-lg">{phase}</p>
                )}

                <p
                    style={{
                        fontSize: '0.12em',
                        background: "#2E3192"
                    }}
                    className="text-center text-white z-30 absolute bottom-0 right-0  px-1 rounded-tl-lg">{name}</p>

                {phase > 0 && (
                    <p
                        style={{
                            fontSize: '0.12em',
                            background: "#FBD34C",
                            color: '#2E3192'
                        }}
                        className="text-center text-white z-30 absolute top-0 left-0  px-1 rounded-br-lg">{phase}</p>
                )}


                {!isImageLoaded && (
                    <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
                        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8" />
                    </div>
                )}
                {/* <img src={url} alt='nft-crowd' className="absolute top-0 left-0 w-full h-full z-20" /> */}
                <img src={url} alt='nft-crowd' className="absolute top-0 left-0 w-full h-full z-20" onLoad={handleImageLoad} style={{ display: isImageLoaded ? 'block' : 'none' }} />
                <div className="absolute top-0 left-0 w-full h-full z-30 " />
            </div>}
    </div>

    );
};

const TokensMobile = ({ fetchEmail, palette, setPalette, missingPalettes, paletteInfo, lists, phase, setPhase, fill, remove, fillBurn, openStats, openGuide, fillEvolve, setLists, isApproved, getApproval, status, burn }) => {

    const syncScrollRefBurn = useRef(null);
    const syncScrollRefEvolve = useRef(null);


    useEffect(() => {
        if (phase <= 2) {
            const list1Container = syncScrollRefBurn.current;
            const list2Container = syncScrollRefEvolve.current;


            function syncScroll() {
                // horizontal scroll so check out the x value
                const scrollLeft = list1Container.scrollLeft;
                const scrollWidth = list1Container.scrollWidth;
                const width = list1Container.clientWidth;

                if (scrollLeft + width >= scrollWidth) {
                    list2Container.scrollLeft = scrollWidth - width;

                } else {
                    list2Container.scrollLeft = scrollLeft;
                }

            }

            list1Container.addEventListener('scroll', syncScroll);

            return () => {
                list1Container.removeEventListener('scroll', syncScroll);
            };
        }

    }, [phase]);

    useEffect(() => {
        if (phase <= 2) {
            const list1Container = syncScrollRefEvolve.current;
            const list2Container = syncScrollRefBurn.current;


            function syncScroll() {
                const scrollLeft = list1Container.scrollLeft;
                const scrollWidth = list1Container.scrollWidth;
                const width = list1Container.clientWidth;

                if (scrollLeft + width >= scrollWidth) {
                    list2Container.scrollLeft = scrollWidth - width;

                } else {
                    list2Container.scrollLeft = scrollLeft;
                }
            }

            list1Container.addEventListener('scroll', syncScroll);

            return () => {
                list1Container.removeEventListener('scroll', syncScroll);
            };
        }
    }, [phase]);

    const renderResultBoxes = () => {

        // get evolve length and burn length not including nulls
        const evolveLength = lists.evolve.filter((i) => parseInt(i.id) >= 0).length;
        const burnLength = lists.burn.filter((i) => parseInt(i.id) >= 0).length;

        // get the minimum length of evolve and burn list 
        const min = Math.min(evolveLength, burnLength);

        // create an array of length min
        const result = Array.from({ length: min }, (_, i) => i);

        return <>
            {result.map((i) => <ResultBox key={i} />)}
        </>
    }


    const moveItem = (sourceList, destinationList, item, atIndex) => {
        setLists((prevLists) => {
            const newLists = { ...prevLists };

            // Remove any objects with null ids from all lists
            Object.keys(newLists).forEach((key) => {
                newLists[key] = newLists[key]?.filter((i) => parseInt(i.id) >= 0);
            });

            // Remove item from source list
            newLists[sourceList] = newLists[sourceList]?.filter((i) => i.id !== item.id);

            // Add item to destination list
            const destinationItems = [...newLists[destinationList]];
            destinationItems.splice(atIndex, 0, item);
            newLists[destinationList] = destinationItems;

            // Check if "burn" and "evolve" lists are the same size
            const burnItems = newLists["burn"] ?? [];
            const evolveItems = newLists["evolve"] ?? [];
            const maxLength = Math.max(burnItems.length, evolveItems.length);
            if (burnItems.length < maxLength) {
                // Add null objects to "burn" list
                const newBurnItems = [...burnItems];
                while (newBurnItems.length < maxLength) {
                    // generate random id for keys
                    const randomId = -1 * Math.floor(Math.random() * 1000000);
                    newBurnItems.push({ id: randomId, name: randomId });
                }
                newLists["burn"] = newBurnItems;
            }
            if (evolveItems.length < maxLength) {
                // Add null objects to "evolve" list
                const newEvolveItems = [...evolveItems];
                while (newEvolveItems.length < maxLength) {
                    const randomId = -1 * Math.floor(Math.random() * 1000000);
                    newEvolveItems.push({ id: randomId, name: randomId });
                }
                newLists["evolve"] = newEvolveItems;
            }

            return newLists;
        });
    }

    const addItem = (list) => {
        let copyWithoutNull = lists.wallet.filter((i) => parseInt(i.id) >= 0 && i?.metadata?.attributes?.filter((attribute) => attribute.trait_type === "EVOLUTION").length === 0);
        if (copyWithoutNull.length > 0) {
            const item = copyWithoutNull[0];
            moveItem("wallet", list, item, 0);
        }

    }

    const removeItem = (list) => {
        let copyWithoutNull = lists[list].filter((i) => parseInt(i.id) >= 0);
        if (copyWithoutNull.length > 0) {
            const item = copyWithoutNull[copyWithoutNull.length - 1];
            moveItem(list, "wallet", item, 0);
        }
    }


    const handleDrop = useCallback(
        (sourceList, destinationList, item, atIndex) => {
            setLists((prevLists) => {
                const newLists = { ...prevLists };

                // Remove any objects with null ids from all lists
                Object.keys(newLists).forEach((key) => {
                    newLists[key] = newLists[key]?.filter((i) => parseInt(i.id) >= 0);
                });

                // Remove item from source list
                newLists[sourceList] = newLists[sourceList]?.filter((i) => i.id !== item.id);

                // Add item to destination list
                const destinationItems = [...newLists[destinationList]];
                destinationItems.splice(atIndex, 0, item);
                newLists[destinationList] = destinationItems;

                // Check if "burn" and "evolve" lists are the same size
                const burnItems = newLists["burn"] ?? [];
                const evolveItems = newLists["evolve"] ?? [];
                const maxLength = Math.max(burnItems.length, evolveItems.length);
                if (burnItems.length < maxLength) {
                    // Add null objects to "burn" list
                    const newBurnItems = [...burnItems];
                    while (newBurnItems.length < maxLength) {
                        // generate random id for keys
                        const randomId = -1 * Math.floor(Math.random() * 1000000);
                        newBurnItems.push({ id: randomId, name: randomId });
                    }
                    newLists["burn"] = newBurnItems;
                }
                if (evolveItems.length < maxLength) {
                    // Add null objects to "evolve" list
                    const newEvolveItems = [...evolveItems];
                    while (newEvolveItems.length < maxLength) {
                        const randomId = -1 * Math.floor(Math.random() * 1000000);
                        newEvolveItems.push({ id: randomId, name: randomId });
                    }
                    newLists["evolve"] = newEvolveItems;
                }

                return newLists;
            });
        },
        []
    );


    const backend = isMobile ? TouchBackend : HTML5Backend;

    return (
        <DndProvider backend={backend}>
            <div className="container flex flex-col justify-center pb-20 items-center mx-auto mt-5 relative z-40">
                <div className="flex flex-col justify-center ">
                    <div className="flex flex-row space-x-3 my-5 w-full justify-center">
                        <button

                            onClick={() => {
                                setPhase(1)
                            }}

                            style={{
                                fontSize: '0.25em',
                                color: '#2E3192',
                                border: phase === 1 ? '3px solid #FBD34C' : '3px solid white'
                            }}
                            className={` bg-white rounded-xl px-3   ${phase === 1 ? '  ' : 'opacity-75'} `}

                        >
                            Phase 1
                        </button>
                        <button

                            onClick={() => {
                                if (config.phase >= 2) {
                                    setPhase(2)
                                }
                            }}

                            style={{
                                fontSize: '0.25em',
                                color: '#2E3192',
                                border: phase === 2 ? '3px solid #FBD34C' : '3px solid white'
                            }}
                            className={` bg-white rounded-xl px-3  ${config.phase < 2 ? 'opacity-50' : (phase === 2 ? '' : 'opacity-75')} `}
                        >
                            Phase 2
                        </button>
                        <button

                            onClick={() => {
                                if (config.phase >= 3) {
                                    setPhase(3)
                                }
                            }}

                            style={{
                                fontSize: '0.25em',
                                color: '#2E3192',
                                border: phase === 3 ? '3px solid #FBD34C' : '3px solid white'
                            }}
                            className={` bg-white rounded-xl px-3  ${config.phase < 3 ? 'opacity-25' : (phase === 3 ? '' : 'opacity-75')} `}

                        >
                            Phase 3
                        </button>
                        <button

                            onClick={() => {
                                openStats()
                            }}

                            style={{
                                fontSize: '0.25em',
                                color: '#2E3192'
                            }}
                            className=" bg-white rounded-xl px-3"
                        >
                            Stats
                        </button>
                        {phase === 3 && <button

                            onClick={() => {
                                openGuide()
                            }}

                            style={{
                                fontSize: '0.25em',
                                color: '#2E3192'
                            }}
                            className=" bg-white rounded-xl px-3"
                        >
                            Guide
                        </button>}
                    </div>

                    {(phase === 2 || phase === 1) && (
                        <>
                            <div
                                className="flex flex-col my-5 w-full justify-center items-center"
                            >
                                <p
                                    style={{
                                        lineHeight: '1em',
                                        fontSize: '0.35em',
                                    }}
                                    className="text-center"
                                >
                                    DRAG & DROP YOUR CROWDS
                                </p>

                            </div>


                            <List
                                // title="DRAG & DROP YOUR CROWDS"
                                onDrop={(item) => handleDrop(item.sourceList, 'wallet', item)}
                                columns={"multi"} list={lists.wallet} sourceList="wallet"
                            />
                        </>
                    )}

                    {phase === 1 && (<div
                        className=" flex flex-row space-x-3 my-5 w-full justify-center"
                    >
                        <button
                            onClick={() => {
                                fill()
                            }
                            }
                            style={{
                                color: "#2E3192",
                                backgroundColor: "white",
                                fontSize: "0.75rem",
                            }}
                            className=" px-2 py-1  rounded-lg"
                        >Fill All</button>
                        <button
                            onClick={() => {
                                remove()
                            }
                            }
                            style={{
                                color: "white",
                                fontSize: "0.75rem",
                            }}
                            className=" underline px-2 py-1  rounded-lg"
                        >Remove All</button>
                        <button
                            onClick={() => {
                                openGuide()
                            }
                            }
                            style={{
                                color: "#2E3192",
                                backgroundColor: "white",
                                fontSize: "0.75rem",
                            }}
                            className=" px-2 py-1  rounded-lg"
                        >Guide</button>
                    </div>)}
                    {phase === 2 && (<div
                        className=" flex flex-row space-x-3 my-5 w-full justify-center"
                    >
                        <button
                            onClick={() => {
                                fill()
                            }
                            }
                            style={{
                                color: "#2E3192",
                                backgroundColor: "white",
                                fontSize: "0.75rem",
                            }}
                            className={` px-2 py-1  rounded-lg ${palette !== "All" && paletteInfo[palette].hasFullSet ? " " : " opacity-50 "}`}
                        >Create Reach</button>
                        <button
                            onClick={() => {
                                remove()
                            }
                            }
                            style={{
                                color: "white",
                                fontSize: "0.75rem",
                            }}
                            className=" underline px-2 py-1  rounded-lg"
                        >Clear</button>
                        <button
                            onClick={() => {
                                openGuide()
                            }
                            }
                            style={{
                                color: "#2E3192",
                                backgroundColor: "white",
                                fontSize: "0.75rem",
                            }}
                            className=" px-2 py-1  rounded-lg"
                        >Guide</button>
                        <Dropdown
                            direction="down"
                            palette={palette}
                            setPalette={setPalette}
                            paletteInfo={paletteInfo}
                        />
                    </div>)}

                    {phase === 3 && <div className="flex flex-row justify-center space-x-20">
                        {/* // isApproved, getApproval, fetchEmail, setLists */}

                        <Phase3 missingPalettes={missingPalettes} nfts={lists}
                            status={status}
                            isApproved={isApproved}
                            getApproval={getApproval}
                            fetchEmail={fetchEmail}
                            setLists={setLists}

                        />

                    </div>}

                    {(phase === 2 || phase === 1) && (
                        <>
                            <div className="flex flex-col justify-center items-center mb-5 space-y-3">
                                {phase === 1 && <>
                                    <List
                                        title="Evolve"
                                        syncScroll={syncScrollRefEvolve}
                                        onDrop={(item) => handleDrop(item.sourceList, 'evolve', item)}
                                        columns={"single"} list={lists.evolve} sourceList="evolve"
                                        hasControls={true}
                                        addItem={() => addItem("evolve")}
                                        removeItem={() => removeItem("evolve")}
                                    />


                                    <List
                                        title="Burn"
                                        hasControls={true}
                                        syncScroll={syncScrollRefBurn}
                                        onDrop={(item) => handleDrop(item.sourceList, 'burn', item)}
                                        columns={"single"} list={lists.burn} sourceList="burn"
                                        removeItem={() => removeItem("burn")}
                                        addItem={() => addItem("burn")}
                                    />
                                </>}

                                {phase === 2 && <>
                                    <List
                                        title="Evolve"
                                        syncScroll={syncScrollRefEvolve}
                                        onDrop={(item) => {
                                            // get length of evolve not including the negative id items
                                            const evolveLength = lists.evolve.filter((item) => item.id > 0).length
                                            if (evolveLength === 0) {
                                                handleDrop(item.sourceList, 'evolve', item)
                                            }
                                        }}
                                        itemLimit={1}
                                        columns={"single"} list={lists.evolve} sourceList="evolve"
                                        hasControls={false}
                                        addItem={() => addItem("evolve")}
                                        removeItem={() => removeItem("evolve")}
                                    />


                                    <List
                                        title="Burn"
                                        hasControls={false}
                                        syncScroll={syncScrollRefBurn}
                                        onDrop={(item) => handleDrop(item.sourceList, 'burn', item)}
                                        columns={"single"} list={lists.burn} sourceList="burn"
                                    />
                                </>}

                            </div>

                            <div className="w-full text-center">

                                <p
                                    style={{
                                        lineHeight: '1em',
                                        fontSize: '0.35em',
                                    }}
                                    className=""
                                >Status:   <span

                                    className={` ${status === "Ready to burn" ? 'text-green-500' : "text-yellow-300"}`}
                                >{status}</span></p>

                                {/* <div
                            className=" flex flex-row space-x-3 w-full justify-center"
                        >
                            <button
                                onClick={() => {
                                    fill()
                                }
                                }
                                style={{
                                    color: "#2E3192",
                                    backgroundColor: "white",
                                    fontSize: "0.75rem",
                                }}
                                className=" px-2 py-1  rounded-lg"
                            >Fill All</button>
                            <button
                                onClick={() => {
                                    remove()
                                }
                                }
                                style={{
                                    color: "#2E3192",
                                    backgroundColor: "white",
                                    fontSize: "0.75rem",
                                }}
                                className=" px-2 py-1  rounded-lg"
                            >Remove All</button>
                            <button
                                onClick={() => {
                                    fillEvolve()
                                }
                                }
                                style={{
                                    color: "#2E3192",
                                    backgroundColor: "white",
                                    fontSize: "0.75rem",
                                }}
                                className=" px-2 py-1  rounded-lg"
                            >Fill Evolve</button>
                            <button
                                onClick={() => {
                                    fillBurn()
                                }
                                }
                                style={{
                                    color: "#2E3192",
                                    backgroundColor: "white",
                                    fontSize: "0.75rem",
                                }}
                                className=" px-2 py-1  rounded-lg"
                            >Fill Burn</button>
                        </div> */}

                            </div>

                            {/* RESULT ROW */}
                            <div className="text-center w-full flex flex-col justify-center items-center">

                                <div
                                    style={{
                                        height: '0.2em',
                                    }}
                                >

                                </div>

                                <div

                                    style={{
                                        minWidth: '1em',
                                        width: '100vw',
                                        maxWidth: phase === 2 ? '17vw' : '75vw',
                                        maxHeight: `1.5em`,
                                        height: "100vh",
                                        padding: '0.1em'
                                    }}

                                    className={`rounded-xl overflow-y-scroll scrollbar-none
                            ${phase === 2 ? 'justify-center' : ''}
border-2 border-white   flex flex-row content-start`} // Add the grid class here
                                >
                                    {renderResultBoxes()}


                                </div>
                                <div className="w-full flex items-center justify-center">
                                    <p
                                        style={{
                                            lineHeight: '1em',
                                            fontSize: '0.25em',
                                            color: "#2E3192"
                                        }}
                                        className="bg-white rounded-b-lg  px-2"
                                    >Result</p>
                                </div>



                            </div>
                        </>
                    )}


                </div>

                {(phase === 1 || phase === 2) && (<>
                    <button

                        onClick={() => {
                            if (isApproved) {
                                if (status === "Ready to burn") {
                                    if (phase === 1) {
                                        burn()
                                    }
                                    else if (phase === 2) {
                                        fetchEmail()
                                    }
                                } else {
                                    alert(status)
                                }
                            } else {
                                getApproval()
                            }
                        }}

                        style={{
                            fontSize: '0.35em',
                            color: '#2E3192'
                        }}
                        className=" bg-white mt-5 rounded-xl px-3"
                    >
                        {isApproved ? 'CONFIRM BURN' : "APPROVE"}
                    </button>
                </>)}


            </div>

        </DndProvider>
    );
}


export default TokensMobile