import http from "../http-common.js"

class DataService {

    getNonce(wallet_address) {
        return http.get(`/user/${wallet_address}/nonce`);
    }

    getStats() {
        return http.get(`/user/stats`);
    }

    getToken(wallet_address, signature) {
        return http.get(`/user/${wallet_address}/signature/${signature}`);
    }


    getWallet(token) {
        return http.get(`/user/wallet`, {
            headers: {
                'x-auth-token': token,
            },
        }
        );
    }

    getSignature(burnIds, evolveIds, functionName, token) {
        return http.post(`/tokens/sign`, {
            tokenIds: burnIds,
            evolveIds: evolveIds,
            functionName: functionName,
        }, {
            headers: {
                'x-auth-token': token,
            },
        }
        );
    }

    // const { burnIds, evolveIds, functionName, transactionHash } = req.body;
    burnTokens(burnIds, evolveIds, functionName, transactionHash, token) {
        return http.post(`/tokens/registerBurn`, {
            burnIds,
            evolveIds,
            functionName,
            transactionHash,
        }, {
            headers: {
                'x-auth-token': token,
            },
        }
        );
    }

      // const { burnIds, evolveIds, functionName, transactionHash } = req.body;
      updatePending(burnIds, evolveIds, functionName, transactionHash, token, email) {
        return http.post(`/tokens/registerPending`, {
            burnIds,
            evolveIds,
            functionName,
            transactionHash,
            email,
        }, {
            headers: {
                'x-auth-token': token,
            },
        }
        );
    }

    getTokens(token) {
        return http.get(`/tokens/metadata`, {
            headers: {
                'x-auth-token': token,
            },
        }
        );
    }

    checkout(tokens, token) {
        return http.post(`/tokens/checkout`, {
            tokens: tokens,
        }, {
            headers: {
                'x-auth-token': token,
            },
        }
        );
    }

}

export default new DataService();