import { useEffect, useState } from "react";
import { useConnect, useAccount, useDisconnect } from 'wagmi'
import { Web3Button } from '@web3modal/react'
import { useGlobalStateStore } from '../stores/GlobalSiteStore';
import { useUserStore } from '../stores/UserStore';
import { ethers } from 'ethers';
import DataService from '../services/DataService';

const Connect = () => {

    const { address, isConnecting, isDisconnected } = useAccount()
    const { connect, connectors, isLoading, pendingConnector } =
        useConnect()
    const { disconnect } = useDisconnect()

    const { setLoading } = useGlobalStateStore()
    const { user, setUser, signOut } = useUserStore()

    const [checkingUser, setCheckingUser] = useState(true)
    const [userSignedOut, setUserSignedOut] = useState(false)

    const [signingIn, setSigningIn] = useState(false)

    useEffect(() => {

        const fetchWalletFromToken = async () => {
            setLoading(true)

            if (!address) {
                setCheckingUser(false)
                signOut()
                setLoading(false)
                return
            }

            if (!checkingUser) {
                setLoading(false)
                return
            }

            // get token from local storage
            const token = localStorage.getItem("token");

            if (token) {
                try {
                    let { wallet_address } = await DataService.getWallet(token).then((res) => {
                        return res.data
                    })


                    if (wallet_address === address.toLowerCase()) {
                        setUser({
                            loggedIn: true,
                            address: address,
                            token: token
                        })
                        setCheckingUser(false)
                    } else {
                        signOut()
                        setCheckingUser(false)
                    }
                } catch (e) {
                    console.log(e)
                    signOut()
                    setCheckingUser(false)
                }


            } else {
                signOut()
                setCheckingUser(false)
            }

            setLoading(false)
        }

        const timeout = setTimeout(() => {
            fetchWalletFromToken()
        }, 100);

        return () => { clearTimeout(timeout) }
    }, [address, checkingUser])

    useEffect(() => {
        const connectWallet = async () => {

            if (checkingUser || userSignedOut) {
                return
            }

            const { ethereum } = window;
            if (ethereum && address && !user.loggedIn) {
                try {


                    const provider = new ethers.providers.Web3Provider(ethereum);
                    const signer = provider.getSigner();
                    setSigningIn(true)


                    // get Nonce
                    let { rawMessage } = await DataService.getNonce(address).then((res) => {
                        return res.data
                    })
                    // Sign the binary data
                    let signature = await signer.signMessage(rawMessage);

                    let { token } = await DataService.getToken(address, signature).then((res) => {
                        return res.data
                    })

                    if (token) {
                        localStorage.setItem("token", token)
                        setUser({
                            loggedIn: true,
                            address: address,
                            token: token
                        })

                        const { chainId } = await provider.getNetwork();
                        // setCurChainId(chainId);
                        // if (chainId === config.correctChain) {
                        //     fetchFighters(accounts[0]);
                        // }
                        setSigningIn(false)

                        return {
                            address,
                        }

                    }
                } catch (error) {
                    disconnect()
                    setUserSignedOut(true)
                    signOut()
                    setSigningIn(false)
                }
            } else {
                setSigningIn(false)

            }
        };
        connectWallet();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, address, checkingUser, userSignedOut])

    return <div
        className={`fixed top-0  w-screen h-screen  flex flex-col items-center justify-center 
        
        transition-all duration-1000 ease-in-out

        ${user.loggedIn ? 'bg-opacity-0 opacity-0 z-0' : 'bg-white bg-opacity-90 opacity-100 z-50'}
        
        `}
    >


        {/* {
            isConnecting  ?
              
                : */}
        {address ?
            <div
                style={{
                    fontSize: '0.3em'
                }}
                className=" ">
                <div className='flex flex-col min-h-[150px] justify-center items-center'>
                    <p className="mb-3 font-bold text-gray-400">Connecting Wallet...</p>
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8" />
                </div>

            </div> :
            <div

                className=" px-10 py-1 pb-8 flex flex-col items-center mt-5">

                {/* <p
                    style={{
                        fontSize: "0.5em",
                        color: '#2E3192',
                    }}

                >
                    CONNECT WALLET
                </p> */}

                {/* Buttons */}
                <div
                    className="flex flex-col items-center justify-center w-full max-w-[450px]"
                >
                    <Web3Button />
                </div>

                {/* IF they dont have ethereum */}
                {!window.ethereum && <div
                    style={{
                        fontSize: "0.45em",
                    }}
                    className="flex flex-col items-center justify-center w-full max-w-[450px]"
                >
                    <p
                        style={{
                            fontSize: "0.45em",
                            color: '#2E3192',
                        }}
                    >
                        Please come back with a compatible browser
                    </p>

                </div>

                }




            </div>
        }



        {/* } */}


    </ div>
}

export default Connect