import axios from "axios";
import { config } from "./config";

const isDev = window.location.hostname === "localhost";

const baseURL = isDev ? config.developmentURL : config.productionURL;


export default axios.create({
    baseURL: baseURL,
    headers: {
        "Content-type": "application/json",
    }
})
