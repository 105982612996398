import {create} from 'zustand'

export const useTransitionStore = create((set) => ({
    leaving: false,
    reset: false,
    location: "",
    setLeaving: (isLeaving) => set({ leaving: isLeaving }),
    setReset: (isReset) => set({ reset: isReset }),
    setLocation: (newLocation) => set({ location: newLocation }),
    goTo: (loc) => set((state) => {
        if (loc !== window.location.pathname) {
            return ({ leaving: true, location: loc })
        } else {
            return (state)
        }
    })
}))