import React, { useEffect, useState } from 'react';
import logo from "../assets/splashLogo.svg"
import loading from "../assets/Splash_LoadingBar.svg"

const useCountUpTimer = (duration) => {
    const [percentage, setPercentage] = useState(0);
  
    useEffect(() => {
      const startTime = Date.now();
      const interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const newPercentage = (elapsedTime / duration) * 100;
  
        if (newPercentage >= 100) {
          setPercentage(100);
          clearInterval(interval);
        } else {
          setPercentage(newPercentage);
        }
      }, 100);
  
      return () => clearInterval(interval);
    }, [duration]);
  
    return percentage;
  };

const Splash = ({ children }) => {
    const [visible, setVisible] = useState(true);

    const [startFade, setStartFade] = useState(false);
    const percentage = useCountUpTimer(3000);


    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setStartFade(true);
        }, 2500);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {visible && (
                <div
                    style={{
                        backgroundColor: "#2E3192",
                    }}
                    className={`fixed inset-0 
                ${startFade ? ' transition-opacity duration-2500 ease-linear opacity-100  ' : '  '}
                z-50
                    flex flex-col justify-center items-center
                `}>
                    <div className=''>
                        <img src={logo} alt="logo"
                            style={{
                                width: '4em',
                            }}
                            className='bg-white' />
                        <div className='relative'>
                            <img src={loading} alt="loading" className='z-10' />
                            <div className='w-full h-full flex items-center justify-center absolute top-0 left-0'>
                                <div
                                    style={{
                                        width: '99%',
                                    }}
                                    className='  flex justify-start z-20 items-center h-full '
                                >
                                    <div className='  h-full '
                                        style={{
                                            backgroundColor: '#f3d650',
                                            width: `${percentage}%`,
                                        }}
                                    ></div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            )}
        </>
    );
};

export default Splash;