import React, { useEffect } from 'react';
import Ticker from 'react-ticker'

import burnTotal from "../assets/statIcons/Burn Total.svg"
import crowdTotal from "../assets/statIcons/Crowd Total.svg"
import size1 from "../assets/statIcons/Size 1.svg"
import size2 from "../assets/statIcons/Size 2.svg"
import size3 from "../assets/statIcons/Size 3.svg"
import size4 from "../assets/statIcons/Size 4.svg"
import size5 from "../assets/statIcons/Size 5.svg"
import reach from "../assets/statIcons/Reach.svg"
import grid from "../assets/statIcons/Grid.svg"
import { useStatsStore } from '../stores/StatsStore';

const TickerWord = ({ img, name, count }) => {

    return <div className=' flex flex-row items-center  justify-center space-x-3'>
        {/* <div className='w-6 h-6 border-2 border-white rounded-full'>
        </div> */}
        <img src={img} 
        style={{
            marginBottom: '0.2em'
        }}
        alt="icon" className='w-6 h-6' />
        <p>{name}: {count !== undefined ? count : "?"}</p>

    </div>
}



const TickerTape = () => {

    const { stats } = useStatsStore();



    return (
        <div
            className='absolute top-0 left-0 w-screen border-b-4 border-white'
        >
            {Object.keys(stats).length > 0 ? <Ticker >
                {({ index }) => (
                    <div className='flex flex-row whitespace-nowrap space-x-20 mr-20'
                        style={{
                            fontSize: '0.35em',
                        }}
                    >
                        <p className='invisible'>p</p>
                        <TickerWord img={crowdTotal} name='CROWDS' count={stats?.totalCrowds?.count} />
                        <TickerWord img={burnTotal} name='BURNED' count={stats?.burnedCrowds?.count} />
                        <TickerWord img={size1} name='Pod' count={stats?.pod?.count} />
                        <TickerWord img={size2} name='Herd' count={stats?.herd?.count} />
                        <TickerWord img={size3} name='Swarm' count={stats?.swarm?.count} />
                        <TickerWord img={size4} name='Mob' count={stats?.mob?.count} />
                        <TickerWord img={size5} name='Colony' count={stats?.colony?.count} />
                        <TickerWord img={reach} name='Reach' count={stats?.reach?.count} />
                        <TickerWord img={grid} name='Grid' count={stats?.grid?.count} />


                    </div>
                )}
            </Ticker> : null}

        </div>

    );
};

export default TickerTape;