import Footer from "../layout/Footer"
import { useWindowSize } from "../helpers/WindowSize"
import Intro from "../components/Intro"
import { useEffect, useState } from "react"
import Tokens from "../components/Tokens"
import FloatingCircles from "../components/FloatingCircles"
import Connect from "../components/Connect"
import TokensMobile from "../components/TokensMobile"
import { ethers } from 'ethers';
import { useGlobalStateStore } from "../stores/GlobalSiteStore"
import DataService from "../services/DataService"
import { useUserStore } from "../stores/UserStore"
import { config } from '../config';
import Web3 from 'web3'
import { useNavigate } from "react-router-dom"
import refresh from "../assets/refresh.svg"
import ImageStack from "../components/ImageStack"
import { useStatsStore } from "../stores/StatsStore"
import Overview from "../components/Popups/Overview"
import Stats from "../components/Popups/Stats"
import Email from "../components/Popups/Email"
// let exampleData = {
//     "wallet": [
//         {
//             "id": "101",
//             "metadata": {
//                 "name": "Crowd #101",
//                 "description": "\"Crowd\" is an infinite-supply generative art collection by Creature World, composing Creatures of every color into endless unique combinations, created over the course of only 24 hours",
//                 "image": "https://storage.googleapis.com/crowd-metadata/public_imgs/101.png",
//                 "attributes": [
//                     {
//                         "trait_type": "PALETTE",
//                         "value": "Forest"
//                     },
//                     {
//                         "trait_type": "COLORS",
//                         "value": "2"
//                     },
//                     {
//                         "trait_type": "EVOLUTION",
//                         "value": "Reach"
//                     }
//                 ],
//                 "external_url": "https://www.creature.world/"
//             }
//         },
//         {
//             "id": "102",
//             "metadata": {
//                 "name": "Crowd #102",
//                 "description": "\"Crowd\" is an infinite-supply generative art collection by Creature World, composing Creatures of every color into endless unique combinations, created over the course of only 24 hours",
//                 "image": "ipfs://bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi/102.png",
//                 "attributes": [
//                     {
//                         "trait_type": "PALETTE",
//                         "value": "Berry Blend"
//                     },
//                     {
//                         "trait_type": "COLORS",
//                         "value": "7"
//                     }
//                 ],
//                 "external_url": "https://www.creature.world/"
//             }
//         },
//         {
//             "id": "103",
//             "metadata": {
//                 "name": "Crowd #103",
//                 "description": "\"Crowd\" is an infinite-supply generative art collection by Creature World, composing Creatures of every color into endless unique combinations, created over the course of only 24 hours",
//                 "image": "https://storage.googleapis.com/crowd-metadata/public_imgs/103.png",
//                 "attributes": [
//                     {
//                         "trait_type": "PALETTE",
//                         "value": "Baby Shower"
//                     },
//                     {
//                         "trait_type": "COLORS",
//                         "value": "4"
//                     },
//                     {
//                         "trait_type": "EVOLUTION",
//                         "value": "Swarm"
//                     }
//                 ],
//                 "external_url": "https://www.creature.world/"
//             }
//         },
//         {
//             "id": "116",
//             "metadata": {
//                 "name": "Crowd #116",
//                 "description": "\"Crowd\" is an infinite-supply generative art collection by Creature World, composing Creatures of every color into endless unique combinations, created over the course of only 24 hours",
//                 "image": "ipfs://bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi/116.png",
//                 "attributes": [
//                     {
//                         "trait_type": "PALETTE",
//                         "value": "Purple Haze"
//                     },
//                     {
//                         "trait_type": "COLORS",
//                         "value": "5"
//                     }
//                 ],
//                 "external_url": "https://www.creature.world/"
//             }
//         },
//         {
//             "id": "191",
//             "metadata": {
//                 "name": "Crowd #191",
//                 "description": "\"Crowd\" is an infinite-supply generative art collection by Creature World, composing Creatures of every color into endless unique combinations, created over the course of only 24 hours",
//                 "image": "https://storage.googleapis.com/crowd-metadata/public_imgs/191.png",
//                 "attributes": [
//                     {
//                         "trait_type": "PALETTE",
//                         "value": "Valentine's Day"
//                     },
//                     {
//                         "trait_type": "COLORS",
//                         "value": "4"
//                     },
//                     {
//                         "trait_type": "EVOLUTION",
//                         "value": "Mob"
//                     }
//                 ],
//                 "external_url": "https://www.creature.world/"
//             }
//         },
//         {
//             "id": "205",
//             "metadata": {
//                 "name": "Crowd #205",
//                 "description": "\"Crowd\" is an infinite-supply generative art collection by Creature World, composing Creatures of every color into endless unique combinations, created over the course of only 24 hours",
//                 "image": "ipfs://bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi/205.png",
//                 "attributes": [
//                     {
//                         "trait_type": "PALETTE",
//                         "value": "Vanilla Caramel"
//                     },
//                     {
//                         "trait_type": "COLORS",
//                         "value": "3"
//                     }
//                 ],
//                 "external_url": "https://www.creature.world/"
//             }
//         },
//         {
//             "id": "217",
//             "metadata": {
//                 "name": "Crowd #217",
//                 "description": "\"Crowd\" is an infinite-supply generative art collection by Creature World, composing Creatures of every color into endless unique combinations, created over the course of only 24 hours",
//                 "image": "https://storage.googleapis.com/crowd-metadata/public_imgs/217.png",
//                 "attributes": [
//                     {
//                         "trait_type": "PALETTE",
//                         "value": "Baby Shower"
//                     },
//                     {
//                         "trait_type": "COLORS",
//                         "value": "6"
//                     },
//                     {
//                         "trait_type": "EVOLUTION",
//                         "value": "Mob"
//                     }
//                 ],
//                 "external_url": "https://www.creature.world/"
//             }
//         },
//         {
//             "id": "218",
//             "metadata": {
//                 "name": "Crowd #218",
//                 "description": "\"Crowd\" is an infinite-supply generative art collection by Creature World, composing Creatures of every color into endless unique combinations, created over the course of only 24 hours",
//                 "image": "https://storage.googleapis.com/crowd-metadata/public_imgs/218.png",
//                 "attributes": [
//                     {
//                         "trait_type": "PALETTE",
//                         "value": "CMYK"
//                     },
//                     {
//                         "trait_type": "COLORS",
//                         "value": "6"
//                     },
//                     {
//                         "trait_type": "EVOLUTION",
//                         "value": "Pod"
//                     }
//                 ],
//                 "external_url": "https://www.creature.world/"
//             }
//         },
//         {
//             "id": "220",
//             "metadata": {
//                 "name": "Crowd #220",
//                 "description": "\"Crowd\" is an infinite-supply generative art collection by Creature World, composing Creatures of every color into endless unique combinations, created over the course of only 24 hours",
//                 "image": "ipfs://bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi/220.png",
//                 "attributes": [
//                     {
//                         "trait_type": "PALETTE",
//                         "value": "Berry Blend"
//                     },
//                     {
//                         "trait_type": "COLORS",
//                         "value": "6"
//                     }
//                 ],
//                 "external_url": "https://www.creature.world/"
//             }
//         }
//     ],
//     "evolve": [],
//     "burn": []
// }

const paletteNames = [
    'Blueberry', 'Tropical', 'Coral Reef', 'Y2K', 'CMYK',
    'Bumblebee', 'Trix Yogurt', 'Rainbow', 'Vibrant', 'Easter Sunday',
    'Greyscale', 'Berry Blend', 'Neopolitan', 'Hot and Cold', 'Baby Shower',
    'Forest', 'Earth', 'Watermelon', 'Greeney Grey', '80\'s Mall',
    'Valentine\'s Day', 'Splash', 'Americana', 'Embers', 'Art Deco',
    'Vanilla Caramel', 'Purple Haze', 'Boot Camp', 'Dragonfruit', 'Apple Cider',
    'Farmer\'s Wheat', 'Primaries'
];

const Burn = (props) => {
    const [goToBurn, setGoToBurn] = useState(false)
    const [fetchedNfts, setFetchedNfts] = useState(false)
    const [haveFetched, setHaveFetched] = useState(false)
    const { user } = useUserStore()
    const [isContractApproved, setIsContractApproved] = useState(false)
    const [actionInProgress, setActionInProgress] = useState(false)
    const [actionText, setActionText] = useState("")

    const [paletteInfo, setPaletteInfo] = useState({})

    const { stats, setStats } = useStatsStore();

    const [statsOpen, setStatsOpen] = useState(false)
    const [guideOpen, setGuideOpen] = useState(false)

    const [showBurning, setShowBurning] = useState(false)

    const [previewImages, setPreviewImages] = useState([])

    const [pendingTransactions, setPendingTransactions] = useState([])

    const [phase, setPhase] = useState(config.phase)

    const [status, setStatus] = useState("")
    const [palette, setPalette] = useState("All")

    const [emailOpen, setEmailOpen] = useState(false)

    const [lists, setLists] = useState({
        wallet: [],
        evolve: [],
        burn: [],
    });

    const targetDate = new Date(config.siteOpenDate);
    const now = new Date();
    const isFuture = targetDate > now;

    if (isFuture) {
        // go back to home page "/"
        window.location.href = "/"
    }

    //    const [lists, setLists] = useState(exampleData);

    const fetchNFTs = async (ids = []) => {

        try {
            await DataService.getStats().then((res) => {
                setStats(res.data.stats)
            })
        } catch (e) {
            console.log("Error fetching stats")
        }


        setMissingPalettes(['fetching...'])

        return await DataService.getTokens(user?.token)
            .then((res) => {
                // seperate on status into nfts, pending and burned
                let newNfts = []
                let newPaletteInfo = {}
                newPaletteInfo["All"] = { hasFullSet: false, colors: {} }
                let numPalettes = 0
                let returnTokens = []
                for (const nft of res.data.nfts) {
                    if (ids) {
                        if (ids.includes(nft.id)) {
                            returnTokens.push(nft)
                        }
                    }

                    let palette = nft.metadata.attributes.find((attr) => attr.trait_type === "PALETTE")
                    let colors = nft.metadata.attributes.find((attr) => attr.trait_type === "COLORS")
                    // if nft has a COLORS attribute
                    if (palette) {
                        // if nft has a PALETTE attribute
                        if (colors) {
                            if (newPaletteInfo[palette.value]) {
                                if (!newPaletteInfo[palette.value].colors[colors.value]) {
                                    newPaletteInfo[palette.value].colors[colors.value] = true
                                }
                            } else {
                                newPaletteInfo[palette.value] = {
                                    hasFullSet: false,
                                    colors: {},
                                }
                                newPaletteInfo[palette.value].colors[colors.value] = true
                            }
                            if (Object.keys(newPaletteInfo[palette.value].colors).length === config.paletteKey[palette.value]) {
                                newPaletteInfo[palette.value].hasFullSet = true
                            }
                        }
                    }

                    newNfts.push(nft)
                }
                if (phase === 2) {
                    setPalette("All")
                }
                setPaletteInfo(newPaletteInfo)
                setFetchedNfts(newNfts)
                setLists({
                    wallet: newNfts,
                    evolve: [],
                    burn: [],
                })

                if (res.data.pendingTransactions) {
                    setPendingTransactions(res.data.pendingTransactions)
                }


                return returnTokens
            })
            .catch((err) => {
                console.log(err)
            })
            .finally((returnTokens) => {
                setHaveFetched(true)
                return returnTokens
            })
    }

    const changePhase = (phase) => {
        setPhase(phase)
        setLists({
            wallet: fetchedNfts,
            evolve: [],
            burn: [],
        })
        setPalette("All")
    }

    const changePaletteAndGrid = (palette) => {
        setPalette(palette)

        let filteredNfts = []
        if (palette === "All") {
            filteredNfts = fetchedNfts
        } else {
            filteredNfts = fetchedNfts.filter((nft) => {
                let paletteAttr = nft.metadata.attributes.find((attr) => attr.trait_type === "PALETTE")
                if (paletteAttr) {
                    if (paletteAttr.value === palette) {
                        return true
                    }
                }
                return false
            })
        }

        setLists({
            wallet: filteredNfts,
            evolve: [],
            burn: [],
        })
    }

    useEffect(() => {

        const handlePending = async (transactions) => {
            if (transactions.length > 0) {
                setActionInProgress(true)
                setActionText("Checking for burn...")

                for (let i = 0; i < transactions.length; i++) {

                    let trans = transactions[i]
                    setActionText("Burning Crowds...")
                    console.log(trans)
                    let result = await checkTx(trans.transactionHash)

                    if (result === 'Success') {
                        setActionText("Evolving Crowds...")

                        await DataService.burnTokens(trans.burnTokens, trans.upgradeTokens, trans.type, trans.transactionHash, user?.token)

                        let evolvedImages = await fetchNFTs(trans.upgradeTokens)
                        setActionInProgress(false)
                        setPreviewImages(evolvedImages)

                    }
                    if (result === 'Failed') {
                        // update that burn transaction to failed


                    }
                }

                setActionText("Please contact support.")
            }
        }

        handlePending(pendingTransactions)


    }, [pendingTransactions])

    useEffect(() => {
        if (user?.loggedIn && !haveFetched) {
            fetchNFTs()
            setPalette("All")
        } else {
            if (!user?.loggedIn) {
                setPalette("All")
                setHaveFetched(false)
                setFetchedNfts([])
                setLists({
                    wallet: [],
                    evolve: [],
                    burn: [],
                })
            }
        }

        if (user?.loggedIn) {
            isApproved()
        }
    }, [user])


    useEffect(() => {

        // length of list but item.id is not null
        let burnLength = lists.burn.filter((item) => parseInt(item.id) >= 0).length
        let evolveLength = lists.evolve.filter((item) => parseInt(item.id) >= 0).length

        // if there are evolve tokens that have metadata.attributes.trait_type === "EVOLUTION"
        let evolvingEvolvedTokens = lists.evolve.filter((item) => item?.metadata && item.metadata.attributes.filter((attribute) => attribute.trait_type === "EVOLUTION").length > 0)


        if (!isContractApproved) {
            setStatus("Need Approval")
            return
        }

        if (burnLength === 0 && evolveLength === 0) {
            setStatus("Needs Crowds")
            return
        }


        if (phase === 1) {
            if (evolvingEvolvedTokens.length > 0) {
                setStatus("Cannot evolve token twice.")
                return
            }

            if (burnLength > evolveLength) {
                setStatus("Needs crowds to evolve")
                return
            }

            if (burnLength < evolveLength) {
                setStatus("Needs crowds to burn")
                return
            }
        }

        if (phase === 2) {

            if (evolveLength !== 1) {
                setStatus("Need one token to evolve")
                return
            }

            let paletteToEvolve = lists.evolve[0].metadata.attributes.find((attr) => attr.trait_type === "PALETTE").value
            let sizeOfEvolve = lists.evolve[0].metadata.attributes.find((attr) => attr.trait_type === "COLORS")?.value

            if (!sizeOfEvolve) {
                setStatus("Missing Sizes")
                return
            }

            // check to see if the other tokens in the burn list have the same palette
            let samePalette = true
            for (let i = 0; i < burnLength; i++) {
                let palette = lists.burn[i].metadata.attributes.find((attr) => attr.trait_type === "PALETTE").value
                if (palette !== paletteToEvolve) {
                    samePalette = false
                }
            }

            if (!samePalette) {
                setStatus("Need same palette")
                return
            }

            // check to see if we have one of each color length
            let colorLength = config.paletteKey[paletteToEvolve]

            if (!colorLength) {
                setStatus("Error with palette")
                return
            }


            let colorCount = {}
            for (let i = 1; i <= colorLength; i++) {
                colorCount[i] = 0
            }

            colorCount[sizeOfEvolve]++

            for (let i = 0; i < burnLength; i++) {
                let color = lists.burn[i].metadata.attributes.find((attr) => attr.trait_type === "COLORS").value
                if (!color) {
                    setStatus("All tokens must have COLORS attribute")
                    return
                }
                colorCount[color]++
            }


            let hasAllColors = true
            for (let i = 1; i <= colorLength; i++) {
                if (colorCount[i] !== 1) {
                    hasAllColors = false
                }
            }

            if (!hasAllColors) {
                setStatus("Wrong Combination")
                return
            }

            if (burnLength + evolveLength !== colorLength) {
                setStatus("Wrong number of tokens")
                return
            }

        }

        if (phase === 3) {

            console.log(evolveLength, burnLength)

            if (evolveLength !== 1) {
                setStatus("Need one token to evolve")
                return
            }

            if (burnLength !== 31) {
                setStatus("Not correct number of crowds")
                return
            }

            // get all palettes in a set burn and evolve
            let palettes = new Set()
            for (let i = 0; i < burnLength; i++) {
                let palette = lists.burn[i].metadata.attributes.find((attr) => attr.trait_type === "PALETTE").value
                palettes.add(palette)
            }

            for (let i = 0; i < evolveLength; i++) {
                let palette = lists.evolve[i].metadata.attributes.find((attr) => attr.trait_type === "PALETTE").value
                palettes.add(palette)
            }

            console.log(palettes)

            if (palettes.size !== 32) {
                setStatus("Need all palettes")
                return
            }


        }


        setStatus("Ready to burn")
        return


    }, [isContractApproved, lists, phase])


    useEffect(() => {
        if (haveFetched) {
            checkForPhase3()
        }
    }, [haveFetched, phase])

    const [missingPalettes, setMissingPalettes] = useState(['fetching...'])

    const checkForPhase3 = async () => {
        if (phase === 3) {

            // print the fetched NFTS
            console.log(fetchedNfts)

            // print pallette info
            console.log(paletteInfo)

            let curMissingPalettes = []
            for (let i in paletteNames) {

                let curPalette = paletteNames[i]
                if (!Object.keys(paletteInfo).includes(curPalette)) {
                    console.log("You dont have the ", curPalette, " palette.")
                    curMissingPalettes.push(curPalette)
                }
            }
            

            setMissingPalettes(curMissingPalettes)

            // num pallets 
            let numPalettes = Object.keys(paletteInfo).length - 1

            // check to see if we have the right number of palettes
            if (numPalettes !== 32) {
                console.log("You dont have all the paletttes - ", numPalettes)
            } else {
                console.log("You have all the palettes")
            }
        }

    }


    const checkTx = async (hash) => {

        // Log which tx hash we're checking
        const web3 = new Web3(Web3.givenProvider);
        // Set interval to regularly check if we can get a receipt
        return await new Promise(resolve => {
            let interval = setInterval(() => {

                web3.eth.getTransactionReceipt(hash, (err, receipt) => {
                    if (err) {
                        console.log(err)
                        resolve("Error")
                        clearInterval(interval)
                    }
                    // If we've got a receipt, check status and log / change text accordingly
                    if (receipt) {
                        if (receipt.status === true) {
                            resolve('Success');
                        } else if (receipt.status === false) {
                            resolve('Failed');
                        }

                        // Clear interval
                        clearInterval(interval)
                    }
                })
            }, 1000)
        })
    }

    const getApproval = async () => {
        const { ethereum } = window;
        if (ethereum) {
            try {
                setActionText("Approving contract...")
                setActionInProgress(true)
                // first check if already approved
                let isAlreadyApproved = await isApproved()
                if (isAlreadyApproved) {
                    console.log("already approved")
                    setActionInProgress(false)
                    return
                }

                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();

                // Create function call data -- eth_call

                const contract = new ethers.Contract(config.mintAddress, config.mintABI, signer);
                const response = await contract.setApprovalForAll(config.burnAddress, true);

                let result = await checkTx(response.hash)

                if (result === "Success") {
                    setIsContractApproved(true)
                    setActionInProgress(false)
                } else {
                    setActionInProgress(false)
                    console.log("ERROR", result)
                }

                // setFighters(tokens)
            } catch (error) {
                console.error(error);
                setActionInProgress(false)
            }
        }
    };

    const isApproved = async () => {
        const { ethereum } = window;
        if (ethereum) {
            try {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();

                // Create function call data -- eth_call

                const contract = new ethers.Contract(config.mintAddress, config.mintABI, signer);
                const response = await contract.isApprovedForAll(user?.address, config.burnAddress);

                if (response) {
                    console.log("Burn Contract Approved")
                } else {
                    console.log("Burn Contract Not Approved")
                }

                setIsContractApproved(response)

                return response
            } catch (error) {
                console.error(error);
            }
        }
    }

    const fillPhase = () => {
        if (phase === 1) {
            fillEvolveAndBurn()
        }
        if (phase === 2) {
            setPhaseReach()
        }
    }

    const fillEvolveAndBurn = () => {
        setLists((prevLists) => {
            const newLists = { ...prevLists };

            let walletItems = newLists["wallet"] ?? [];
            let evolveItems = newLists["evolve"] ?? [];
            let burnItems = newLists["burn"] ?? [];
            // remove all the null items from the lists
            walletItems = walletItems.filter((item) => parseInt(item.id) >= 0 && item?.metadata?.attributes?.filter((attribute) => attribute.trait_type === "EVOLUTION").length === 0);
            evolveItems = evolveItems.filter((item) => parseInt(item.id) >= 0);
            burnItems = burnItems.filter((item) => parseInt(item.id) >= 0);

            let currentList = evolveItems.length <= burnItems.length ? "evolve" : "burn";

            let totalItems = walletItems.length + evolveItems.length + burnItems.length;

            // Distribute items from wallet to evolve and burn lists
            for (let i = 0; i < walletItems.length; i++) {
                const item = walletItems[i];
                if (currentList === "evolve") {
                    evolveItems.push(item);
                } else {
                    burnItems.push(item);
                }
                // check to see which list is shorter 
                currentList = evolveItems.length <= burnItems.length ? "evolve" : "burn";

                // if total items is odd, and there is only one item left in wallet, break
                if (totalItems % 2 === 1 && i === walletItems.length - 2) {
                    break;
                }
            }

            // Calculate the new wallet list
            const itemsUsed = Math.min(walletItems.length, evolveItems.length + burnItems.length);
            let newWalletItems = walletItems.slice(itemsUsed);

            // add back in all the tokens that wre upgraded that we did not include in teh wallet
            let notIncluded = newLists["wallet"] ?? [];
            notIncluded = notIncluded.filter((item) => parseInt(item.id) >= 0 && item?.metadata?.attributes?.filter((attribute) => attribute.trait_type === "EVOLUTION").length === 1);

            newWalletItems = [...newWalletItems, ...notIncluded];

            // Update the lists
            newLists["wallet"] = newWalletItems;
            newLists["evolve"] = evolveItems;
            newLists["burn"] = burnItems;

            return newLists;
        });
    };

    const setPhaseReach = () => {
        setLists((prevLists) => {
            const newLists = { ...prevLists };

            if (palette === "All" || !paletteInfo[palette]?.hasFullSet) {
                return newLists;
            }

            let filteredNfts = fetchedNfts.filter((nft) => {
                let paletteAttr = nft.metadata.attributes.find((attr) => attr.trait_type === "PALETTE")
                if (paletteAttr) {
                    if (paletteAttr.value === palette) {
                        return true
                    }
                }
                return false
            })

            // find out how many colors are in the palette 
            let numColors = config.paletteKey[palette]
            let newEvolveList = []
            let newBurnList = []

            // put the 1 color in the evolve list and the rest in the burn list
            for (let i = 1; i <= numColors; i++) {

                // find and remove the 1 color from the wallet
                let colorX = filteredNfts.find((nft) => {
                    let colorAttr = nft.metadata.attributes.find((attr) => attr.trait_type === "COLORS")
                    if (colorAttr) {
                        if (parseInt(colorAttr.value) === i) {
                            return true
                        }
                    }
                    return false
                })
                if (colorX) {
                    if (i === 1) {
                        newEvolveList.push(colorX)
                    } else {
                        newBurnList.push(colorX)
                    }
                    filteredNfts = filteredNfts.filter((nft) => nft.id !== colorX.id)
                }


            }

            newLists["evolve"] = newEvolveList
            newLists["burn"] = newBurnList
            newLists["wallet"] = filteredNfts

            return newLists;
        });
    };

    const clear = () => {
        setLists({
            // take all the items from burn and evolve and put them back in wallet but not null items
            wallet: [...lists.wallet, ...lists.burn, ...lists.evolve].filter((item) => parseInt(item.id) >= 0),
            evolve: [],
            burn: [],
        })
    }

    const autoFill = (listToFill) => {

        let oppositeList;
        if (listToFill === "evolve") {
            oppositeList = "burn"
        } else {
            oppositeList = "evolve"
        }

        // check if there is more burn than evolve
        // get length without null
        let toFillListLength = lists[listToFill].filter((item) => parseInt(item.id) >= 0).length
        let oppositelistLength = lists[oppositeList].filter((item) => parseInt(item.id) >= 0).length

        if (toFillListLength >= oppositelistLength) {
            return
        }

        setLists((prevLists) => {
            const newLists = { ...prevLists };

            let walletItems = newLists["wallet"] ?? [];
            let toFillListItems = newLists[listToFill] ?? [];
            let oppositeListItems = newLists[oppositeList] ?? [];

            // remove all the null items from the lists
            walletItems = walletItems.filter((item) => parseInt(item.id) >= 0 && item?.metadata?.attributes?.filter((attribute) => attribute.trait_type === "EVOLUTION").length === 0);
            toFillListItems = toFillListItems.filter((item) => parseInt(item.id) >= 0);
            oppositeListItems = oppositeListItems.filter((item) => parseInt(item.id) >= 0);


            while (toFillListItems.length < oppositeListItems.length) {
                let item = walletItems.pop()
                toFillListItems.push(item)
            }
            // add back in all the tokens that wre upgraded that we did not include in teh wallet
            let notIncluded = newLists["wallet"] ?? [];
            notIncluded = notIncluded.filter((item) => parseInt(item.id) >= 0 && item?.metadata?.attributes?.filter((attribute) => attribute.trait_type === "EVOLUTION").length === 1);

            walletItems = [...walletItems, ...notIncluded];


            // Update the lists
            newLists["wallet"] = walletItems;
            newLists[listToFill] = toFillListItems;
            newLists[oppositeList] = oppositeListItems;

            return newLists;
        });
    }

    const size = useWindowSize()
    // get the size the screen items are based off of
    const getSize = () => {

        let multiplier = 8
        let max = 125
        let min = 75

        let overall = (Math.ceil((size.width * size.height) * 0.00001 * multiplier))

        if (overall > max) {
            overall = max
        } else if (overall < min) {
            overall = min
        }
        overall = overall.toString() + "px"
        return overall
    }

    const getIdsFromList = (list) => {
        let ids = []
        for (const nft of list) {
            let id = parseInt(nft.id)
            if (id >= 0) {
                ids.push(nft.id)
            }
        }
        return ids
    }

    const burn = async (email = "") => {
        // const { walletAddress, tokenIds, evolveIds, functionName } = req.body;
        setActionText("Validating crowds...")
        setActionInProgress(true)

        let burnIds = getIdsFromList(lists.burn)
        let evolveIds = getIdsFromList(lists.evolve)


        let functionName;
        if (phase === 3) {
            functionName = "burn32"
        } else if (phase === 2) {
            functionName = "burn8"
        } else {
            functionName = "burn2"
        }

        const { ethereum } = window;
        if (ethereum) {
            try {
                let signResponse = await DataService.getSignature(burnIds, evolveIds, functionName, user?.token)

                const { signature } = signResponse.data
                if (!signature) {
                    alert("Error processing request. Please try again later.")
                    return
                }
                setActionText("Waiting on user signature...")


                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();


                // Create function call data -- eth_call

                const contract = new ethers.Contract(config.burnAddress, config.burnABI, signer);
                const response = await contract[functionName](burnIds, evolveIds, signature);


                // add pending to the 
                await DataService.updatePending(burnIds, evolveIds, functionName, response.hash, user?.token, email)

                setActionText("Burning Crowds...")

                // put into DB
                // await DataService.burnTokens(burnIds, evolveIds, response.hash, user?.token)

                let result = await checkTx(response.hash)

                setActionText("Evolving Crowds...")

                await new Promise(r => setTimeout(r, 250));

                // update burn in the DB
                // await DataService.updateBurnStatus(response.hash, result, user?.token)
                if (result === "Success") {

                    await DataService.burnTokens(burnIds, evolveIds, functionName, response.hash, user?.token)

                    let evolvedImages = await fetchNFTs(evolveIds)
                    if (phase === 3) {
                        await checkForPhase3()
                    }
                    setActionInProgress(false)

                    setPreviewImages(evolvedImages)
                } else {
                    await fetchNFTs()
                    setActionInProgress(false)
                    alert("ERROR BURNING")
                }

                // pull new NFTs and refresh the gallery
                // await getNFTs()


            } catch (error) {
                setActionText("Error with Burn... Refetching NFTs...")
                if (phase === 2) {
                    changePaletteAndGrid("All")
                }
                await fetchNFTs()

                if (phase === 3) {
                    await checkForPhase3()
                }
                alert("Error validating Crowds. Please try again.")
                console.log(error)
            }
        } else {
            alert("Error connecting to wallet. Please try again later.")
        }

        setActionInProgress(false)

    }

    const overall = getSize()
    const navigate = useNavigate();

    return <div
        style={{
            fontSize: overall,
            fontFamily: "pixel",
            color: "#f2f2f2"
        }}
    >

        <button
            onClick={() => {
                navigate('/')
            }
            }
            style={{
                fontSize: "1rem",
            }}
            className="absolute top-5 left-5 z-50"
        >
            {`<`}  GO BACK
        </button>

        {previewImages.length > 0 ?
            <div
                style={{
                    backgroundColor: '#2E3192'
                }}
                className="fixed top-0 left-0 w-screen h-screen z-50 flex flex-col justify-center items-center">

                <ImageStack images={previewImages} setPreviewImages={setPreviewImages} />


            </div> : null
        }


        {!haveFetched && user?.loggedIn ? <div className='fixed top-0 left-0 w-screen flex flex-col justify-center items-center bg-opacity-75 text-white min-h-screen bg-black  z-50'>
            <div className='flex flex-col justify-center items-center'>
                <p
                    style={{
                        fontSize: "2rem"
                    }}
                    className='text-center'>Fetching Tokens...</p>
                <div className=" animate-spin">
                    <img src={refresh} className="h-16 w-16" />
                </div>
            </div>
        </div> : null}

        {actionInProgress && user?.loggedIn ? <div className='fixed top-0 left-0 w-screen flex flex-col justify-center items-center bg-opacity-75 text-white min-h-screen bg-black  z-50'>
            <div className='flex flex-col justify-center items-center'>
                <p
                    style={{
                        fontSize: "2rem"
                    }}
                    className='text-center'>{actionText ? actionText : 'Please wait...'}</p>
                <div className=" animate-spin">
                    <img src={refresh} className="h-16 w-16" />
                </div>
            </div>
        </div> : null}

        {statsOpen && <Stats close={() => setStatsOpen(false)} stats={stats} />}

        {emailOpen && <Email close={() => setEmailOpen(false)} burn={(email) => burn(email)} />}

        {guideOpen && <Overview close={() => setGuideOpen(false)} />}

        <div>

            <div>
                <Connect />
            </div>

            <div className="hidden lg:block">
                <Tokens
                    gas={stats?.gas}
                    fetchEmail={() => setEmailOpen(true)}
                    paletteInfo={paletteInfo}
                    setPalette={(pal) => changePaletteAndGrid(pal)}
                    palette={palette}
                    phase={phase}
                    setPhase={(phase) => changePhase(phase)}
                    openStats={() => setStatsOpen(true)}
                    openGuide={() => setGuideOpen(true)}
                    missingPalettes={missingPalettes}
                    lists={lists}
                    fill={fillPhase}
                    fillBurn={() => autoFill('burn')}
                    fillEvolve={() => autoFill('evolve')}
                    remove={clear}
                    setLists={setLists}
                    status={status}
                    isApproved={isContractApproved}
                    getApproval={getApproval}
                    burn={burn}
                />
            </div>

            <div className="lg:hidden">
                <TokensMobile
                    gas={stats?.gas}
                    fetchEmail={() => setEmailOpen(true)}
                    paletteInfo={paletteInfo}
                    setPalette={(pal) => changePaletteAndGrid(pal)}
                    palette={palette}
                    phase={phase}
                    setPhase={(phase) => changePhase(phase)}
                    openStats={() => setStatsOpen(true)}
                    openGuide={() => setGuideOpen(true)}
                    missingPalettes={missingPalettes}
                    lists={lists}
                    fill={fillPhase}
                    fillBurn={() => autoFill('burn')}
                    fillEvolve={() => autoFill('evolve')}
                    remove={clear}
                    setLists={setLists}
                    status={status}
                    isApproved={isContractApproved}
                    getApproval={getApproval}
                    burn={burn}
                />
            </div>
        </div>
        {/* <FloatingCircles /> */}


        <Footer />
    </div>
}

export default Burn