import { useEffect, useState, useCallback, useRef } from "react";
import { config } from '../config';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrop, useDrag } from 'react-dnd';
import Dropdown from "./Dropdown";
import Phase3 from "./Phase3";

let titleHeight = '0.35em'

const ResultBox = () => {

    return <div
        className={`bg-white  relative shadow flex flex-col justify-center items-center `} // Add the square class here
        style={{
            width: '1em',
            height: '1em',
            margin: '0.1em',
            color: "#2E3192"
        }}
    >
        <p
            style={{
                lineHeight: '1em',
                fontSize: '1em',
            }}
            className="mt-3"
        >?</p>

    </div>
}

const useCombinedRefs = (...refs) => {
    const targetRef = useRef(null);

    useEffect(() => {
        refs.forEach((ref) => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
};


const List = ({ title, children, onDrop, list, itemLimit, sourceList, columns, syncScroll }) => {
    const [{ isOver }, drop] = useDrop({
        accept: 'item',
        drop: (item) => onDrop(item),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const combinedRef = useCombinedRefs(drop, syncScroll);


    return (
        <div className={`relative  flex flex-col justify-center items-center `} // Add the square class here
        >
            <div
                style={{
                    height: titleHeight,
                }}
            >
                <p
                    style={{
                        lineHeight: '1em',
                        fontSize: '0.35em',
                    }}
                >{title}</p>
            </div>


            <div
                // ref={drop}
                ref={combinedRef}
                style={{
                    minWidth: '1em',
                    width: '100vw',
                    maxWidth: `${columns === 'single' ? '1.5em' : '5.1em'}`,
                    maxHeight: itemLimit ? `${itemLimit * 17}vh` : '65vh',
                    height: "100vh",
                    padding: '0.1em'
                }}

                className={`rounded-xl  scrollbar-none
                ${itemLimit < 5 ? ' justify-center  ' : ' overflow-y-scroll '}
         border-2 border-white border-opacity-50  flex flex-wrap content-start`} // Add the grid class here
            >
                {list.map((item) => {
                    let url = ''
                    let parsedId = parseInt(item.id)
                    let phase = 0
                    if (parsedId >= 0) {
                        let isEvolved = item.metadata?.attributes?.some((attribute) => { return attribute.trait_type === "EVOLUTION" })
                        if (isEvolved) {
                            let phaseAttribute = item.metadata.attributes.find((attribute) => { return attribute.trait_type === "EVOLUTION" })
                            if (phaseAttribute.value === 'Grid') {
                                phase = 3
                            } else if (phaseAttribute.value === 'Reach') {
                                phase = 2
                            } else {
                                phase = 1
                            }

                            url = item.metadata.image
                        } else {
                            url = `https://bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi.ipfs.dweb.link/${item.id}.png`
                        }
                    }

                    return (
                        <Item key={item.id} phase={phase} metadata={item?.metadata} name={item.id} url={url} {...item} sourceList={sourceList} />
                    )
                })}
            </div>
        </div>

    );
};

const Item = ({ id, name, url, listId, metadata, phase, index, className, style, onClick, onMouseEnter, onMouseLeave, sourceList }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'item',
        item: { id, name, url, listId, metadata, index, sourceList },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsImageLoaded(true);
    };


    const colors = metadata?.attributes?.find((attribute) => { return attribute.trait_type === "COLORS" })?.value

    return (parseInt(id) < 0 ?
        <div
            className={`  
                } ${className}`} // Add the square class here
            style={{
                ...style,
                width: '1em',
                height: '1em',
                margin: '0.1em',
            }}
        ></div> :
        <div
            ref={drag}
            className={`  relative shadow ${isDragging ? 'opacity-50' : ''
                } ${className}`} // Add the square class here
            style={{
                ...style,
                width: '1em',
                height: '1em',
                margin: '0.1em',
            }}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >

            {phase > 0 && (
                <p
                    style={{
                        fontSize: '0.12em',
                        background: "#FBD34C",
                        color: '#2E3192'
                    }}
                    className="text-center text-white z-30 absolute top-0 left-0  px-1 rounded-br-lg">{phase}</p>
            )}

            {colors && (
                <p
                    style={{
                        fontSize: '0.12em',
                        // background: "#FBD34C",
                        color: '#FBD34C'
                    }}
                    className="text-center text-white bg-gray-400 z-30 absolute top-0 right-0  px-1 rounded-bl-lg">{colors}</p>
            )}

            <p
                style={{
                    fontSize: '0.12em',
                    background: "#2E3192"
                }}
                className="text-center text-white z-30 absolute bottom-0 right-0  px-1 rounded-tl-lg">{name}</p>
            {!isImageLoaded && (
                <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8" />
                </div>
            )}
            {/* <img src={url} alt='nft-crowd' className="absolute top-0 left-0 w-full h-full z-20" /> */}
            <img src={url} alt='nft-crowd' className="absolute top-0 left-0 w-full h-full z-20" onLoad={handleImageLoad} style={{ display: isImageLoaded ? 'block' : 'none' }} />
        </div>
    );
};

const Tokens = ({ gas, fetchEmail, missingPalettes, palette, setPalette, paletteInfo, lists, phase, setPhase, fill, remove, fillBurn, openStats, openGuide, fillEvolve, setLists, isApproved, getApproval, status, burn }) => {

    const syncScrollRefBurn = useRef(null);
    const syncScrollRefEvolve = useRef(null);

    useEffect(() => {
        if (phase === 1) {
            const list1Container = syncScrollRefBurn.current;
            const list2Container = syncScrollRefEvolve.current;


            function syncScroll() {
                const scrollTop = list1Container.scrollTop;
                const scrollHeight = list1Container.scrollHeight;
                const height = list1Container.clientHeight;

                if (scrollTop + height >= scrollHeight) {
                    list2Container.scrollTop = scrollHeight - height;

                } else {
                    list2Container.scrollTop = scrollTop;
                }
            }

            list1Container.addEventListener('scroll', syncScroll);

            return () => {
                list1Container.removeEventListener('scroll', syncScroll);
            };
        }

    }, [phase]);

    useEffect(() => {
        if (phase === 1) {
            const list1Container = syncScrollRefEvolve.current;
            const list2Container = syncScrollRefBurn.current;


            function syncScroll() {
                const scrollTop = list1Container.scrollTop;
                const scrollHeight = list1Container.scrollHeight;
                const height = list1Container.clientHeight;

                if (scrollTop + height >= scrollHeight) {
                    list2Container.scrollTop = scrollHeight - height;

                } else {
                    list2Container.scrollTop = scrollTop;
                }
            }

            list1Container.addEventListener('scroll', syncScroll);

            return () => {
                list1Container.removeEventListener('scroll', syncScroll);
            };
        }
    }, [phase]);


    const renderResultBoxes = (status) => {
        // get evolve length and burn length not including nulls
        let result = [];
        if (config.phase === 1) {
            const evolveLength = lists.evolve.filter((i) => i.id !== null).length;
            const burnLength = lists.burn.filter((i) => i.id !== null).length;

            // get the minimum length of evolve and burn list 
            const min = Math.min(evolveLength, burnLength);

            // create an array of length min
            result = Array.from({ length: min }, (_, i) => i);
        } else {
            if (status === 'Ready to burn') {
                result = Array.from({ length: 1 }, (_, i) => i);
            }
            // create an array of length min
        }


        return <>
            {result.map((i) => <ResultBox key={i} />)}
        </>
    }

    // const handleDrop = useCallback(
    //     (sourceList, destinationList, item, atIndex) => {
    //         setLists((prevLists) => {
    //             const newLists = { ...prevLists };

    //             // TODO filter all the lists and remove all objects with null ids


    //             // remove item from source list
    //             newLists[sourceList] = newLists[sourceList]?.filter(
    //                 (i) => i.id !== item.id
    //             );

    //             // add item to destination list
    //             const destinationItems = newLists[destinationList];
    //             destinationItems.splice(atIndex, 0, item);
    //             newLists[destinationList] = destinationItems;

    //             // TODO check to see if the lists 'burn' and 'evolve' are the same size 


    //             // TODO if they arent, add null objects to the smaller list


    //             return newLists;
    //         });
    //     },
    //     []
    // );

    const handleDrop = useCallback(
        (sourceList, destinationList, item) => {
            setLists((prevLists) => {
                const newLists = { ...prevLists };

                // Remove any objects with null ids from all lists
                Object.keys(newLists).forEach((key) => {
                    newLists[key] = newLists[key]?.filter((i) => parseInt(i.id) >= 0);
                });

                // Remove item from source list
                newLists[sourceList] = newLists[sourceList]?.filter((i) => i.id !== item.id);

                // Add item to destination list
                const destinationItems = [...newLists[destinationList]];
                destinationItems.splice(undefined, 0, item);
                newLists[destinationList] = destinationItems;

                // Check if "burn" and "evolve" lists are the same size
                const burnItems = newLists["burn"] ?? [];
                const evolveItems = newLists["evolve"] ?? [];
                const maxLength = Math.max(burnItems.length, evolveItems.length);
                if (burnItems.length < maxLength) {
                    // Add null objects to "burn" list
                    const newBurnItems = [...burnItems];
                    while (newBurnItems.length < maxLength) {
                        // generate random id for keys
                        const randomId = -1 * Math.floor(Math.random() * 1000000);
                        newBurnItems.push({ id: randomId, name: randomId });
                    }
                    newLists["burn"] = newBurnItems;
                }
                if (evolveItems.length < maxLength) {
                    // Add null objects to "evolve" list
                    const newEvolveItems = [...evolveItems];
                    while (newEvolveItems.length < maxLength) {
                        const randomId = -1 * Math.floor(Math.random() * 1000000);
                        newEvolveItems.push({ id: randomId, name: randomId });
                    }
                    newLists["evolve"] = newEvolveItems;
                }

                return newLists;
            });
        },
        []
    );

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="container flex flex-col justify-center items-center mx-auto mb-5 relative z-40">
                <div className="flex flex-row space-x-3 my-5 ">
                    <button

                        onClick={() => {
                            setPhase(1)
                        }}

                        style={{
                            fontSize: '0.25em',
                            color: '#2E3192',
                            border: phase === 1 ? '3px solid #FBD34C' : '3px solid white'
                        }}
                        className={` bg-white rounded-xl px-3   ${phase === 1 ? '  ' : 'opacity-75'} `}

                    >
                        Phase 1
                    </button>
                    <button

                        onClick={() => {
                            if (config.phase >= 2) {
                                setPhase(2)
                            }
                        }}

                        style={{
                            fontSize: '0.25em',
                            color: '#2E3192',
                            border: phase === 2 ? '3px solid #FBD34C' : '3px solid white'
                        }}
                        className={` bg-white rounded-xl px-3  ${config.phase < 2 ? 'opacity-50' : (phase === 2 ? '' : 'opacity-75')} `}
                    >
                        Phase 2
                    </button>
                    <button

                        onClick={() => {
                            if (config.phase >= 3) {
                                setPhase(3)
                            }
                        }}

                        style={{
                            fontSize: '0.25em',
                            color: '#2E3192',
                            border: phase === 3 ? '3px solid #FBD34C' : '3px solid white'
                        }}
                        className={` bg-white rounded-xl px-3  ${config.phase < 3 ? 'opacity-25' : (phase === 3 ? '' : 'opacity-75')} `}

                    >
                        Phase 3
                    </button>
                    <button

                        onClick={() => {
                            openStats()
                        }}

                        style={{
                            fontSize: '0.25em',
                            color: '#2E3192'
                        }}
                        className=" bg-white rounded-xl px-3"
                    >
                        Stats
                    </button>
                    {phase === 3 && <button
                        onClick={() => {
                            openGuide()

                        }
                        }
                        style={{
                            fontSize: '0.25em',
                            color: '#2E3192'
                        }}
                        className=" bg-white rounded-xl px-3"
                    >Guide</button>}

                </div>


                {phase === 1 && <div className="flex flex-row justify-center space-x-20">
                    <div className="flex flex-col items-center">
                        <List
                            title="DRAG & DROP YOUR CROWDS"
                            onDrop={(item) => handleDrop(item.sourceList, 'wallet', item)}
                            columns={"multi"} list={lists.wallet} sourceList={"wallet"}
                        />
                        <button
                            onClick={() => {
                                fill()
                            }
                            }
                            style={{
                                color: "#2E3192",
                                backgroundColor: "white",
                                fontSize: "0.75rem",
                            }}
                            className=" px-2 py-1  rounded-lg mt-2"
                        >Input All Crowds</button>
                        <button
                            onClick={() => {
                                remove()
                            }
                            }
                            style={{
                                color: "white",
                                fontSize: "0.75rem",
                            }}
                            className=" px-2 py-1  rounded-lg"
                        >Remove all Crowds</button>
                    </div>


                    <div className="flex flex-row space-x-5">
                        <div className="flex flex-col items-center">

                            <List
                                title="Evolve"
                                syncScroll={syncScrollRefEvolve}
                                onDrop={(item) => handleDrop(item.sourceList, 'evolve', item)}
                                columns={"single"}
                                list={lists.evolve}
                                sourceList={"evolve"}
                            />
                            <button
                                onClick={() => {
                                    fillEvolve()
                                }
                                }
                                style={{
                                    color: "#2E3192",
                                    backgroundColor: "white",
                                    fontSize: "0.75rem",
                                }}
                                className=" px-2 py-1  rounded-lg mt-2"
                            >Fill Evolve</button>

                        </div>
                        <div
                            style={{
                                marginTop: "0.5em",
                                maxHeight: '65vh',
                                height: "100vh",
                                fontSize: '0.5em',

                            }}
                            className="
                            tracking-tighter
                            flex flex-col justify-evenly"
                        >
                            <p>+</p>
                        </div>
                        <div className="flex flex-col items-center">

                            <List
                                title="Burn"
                                onDrop={(item) => handleDrop(item.sourceList, 'burn', item)}
                                syncScroll={syncScrollRefBurn}
                                columns={"single"}
                                list={lists.burn}
                                sourceList={"burn"}
                            />
                            <button
                                onClick={() => {
                                    fillBurn()
                                }
                                }
                                style={{
                                    color: "#2E3192",
                                    backgroundColor: "white",
                                    fontSize: "0.75rem",
                                }}
                                className=" px-2 py-1  rounded-lg mt-2"
                            >Fill Burn</button>

                        </div>

                        <div
                            style={{
                                marginTop: "0.5em",
                                maxHeight: '65vh',
                                height: "100vh",
                                fontSize: '0.5em',

                            }}
                            className="
                            tracking-tighter 
                            flex flex-col justify-evenly"
                        >
                            <p>=</p>
                        </div>

                        <div className="text-center">

                            <div
                                style={{
                                }}
                            >

                            </div>
                            <div className="relative"
                                style={{
                                    height: titleHeight,
                                }}
                            >
                                <p
                                    style={{
                                        lineHeight: '1em',
                                        fontSize: '0.15em',
                                    }}
                                    className=""
                                >Status: </p>
                                <p
                                    style={{
                                        lineHeight: '1em',
                                        fontSize: '0.15em',
                                    }}
                                    className={` ${status === "Ready to burn" ? 'text-green-500' : "text-yellow-300"}`}
                                >{status}</p>
                            </div>
                            <div className="flex flex-col items-center">

                                <div

                                    style={{
                                        minWidth: '1em',
                                        width: '100vw',
                                        maxWidth: ` 1.5em `,
                                        maxHeight: '65vh',
                                        height: "100vh",
                                        padding: '0.1em'
                                    }}

                                    className={`rounded-xl overflow-y-scroll scrollbar-none
border-2 border-white border-opacity-50  flex flex-wrap content-start`} // Add the grid class here
                                >
                                    {renderResultBoxes()}


                                </div>
                                <button
                                    onClick={() => {
                                        openGuide()

                                    }
                                    }
                                    style={{
                                        color: "#2E3192",
                                        backgroundColor: "white",
                                        fontSize: "0.75rem",
                                    }}
                                    className=" px-2 py-1  rounded-lg mt-2"
                                >Guide</button>

                            </div>
                        </div>


                    </div>

                </div>}

                {phase === 2 && <div className="flex flex-row justify-center space-x-20">
                    <div className="flex flex-col items-center">
                        <List
                            title="DRAG & DROP YOUR CROWDS"
                            onDrop={(item) => handleDrop(item.sourceList, 'wallet', item)}
                            columns={"multi"} list={lists.wallet} sourceList={"wallet"}

                        />
                        <div className="flex flex-row  items-center w-full justify-evenly my-3">
                            <Dropdown
                                direction="up"
                                palette={palette}
                                setPalette={setPalette}
                                paletteInfo={paletteInfo}
                            />
                            <button
                                onClick={() => {
                                    fill()
                                }
                                }
                                style={{
                                    color: "#2E3192",
                                    backgroundColor: "white",
                                    fontSize: "0.75rem",
                                }}
                                className={` px-2 py-1  rounded-lg ${palette !== "All" && paletteInfo[palette].hasFullSet ? " " : " opacity-50 "}`}
                            >Create Reach</button>
                            <button
                                onClick={() => {
                                    remove()
                                }
                                }
                                style={{
                                    color: "white",
                                    fontSize: "0.75rem",
                                }}
                                className=" px-2 py-1  rounded-lg"
                            >Remove</button>

                        </div>

                    </div>


                    <div className="flex flex-row space-x-5">
                        <div className="flex flex-col items-center">

                            <List
                                title="Evolve"
                                onDrop={(item) => {
                                    // get length of evolve not including the negative id items
                                    const evolveLength = lists.evolve.filter((item) => item.id > 0).length
                                    if (evolveLength === 0) {
                                        handleDrop(item.sourceList, 'evolve', item)
                                    }
                                }}
                                columns={"single"}
                                list={lists.evolve}
                                itemLimit={1}
                                sourceList={"evolve"}
                            />
                            {/* <button
                                onClick={() => {
                                    fillEvolve()
                                }
                                }
                                style={{
                                    color: "#2E3192",
                                    backgroundColor: "white",
                                    fontSize: "0.75rem",
                                }}
                                className=" px-2 py-1  rounded-lg mt-2 mb-5"
                            >Fill Evolve</button> */}

                        </div>
                        <div
                            style={{
                                marginTop: "0.5em",
                                maxHeight: '65vh',
                                height: "100vh",
                                fontSize: '0.5em',

                            }}
                            className="
                            tracking-tighter
                            flex flex-col justify-evenly"
                        >
                            <p>+</p>
                        </div>
                        <div className="flex flex-col items-center">

                            <List
                                title="Burn"
                                onDrop={(item) => handleDrop(item.sourceList, 'burn', item)}
                                columns={"single"}
                                list={lists.burn}
                                sourceList={"burn"}
                            />


                        </div>

                        <div
                            style={{
                                marginTop: "0.5em",
                                maxHeight: '65vh',
                                height: "100vh",
                                fontSize: '0.5em',

                            }}
                            className="
                            tracking-tighter 
                            flex flex-col justify-evenly"
                        >
                            <p>=</p>
                        </div>

                        <div className="text-center">

                            <div
                                style={{
                                }}
                            >

                            </div>
                            <div className="relative"
                                style={{
                                    height: titleHeight,
                                }}
                            >
                                <p
                                    style={{
                                        lineHeight: '1em',
                                        fontSize: '0.15em',
                                    }}
                                    className=""
                                >Status: </p>
                                <p
                                    style={{
                                        lineHeight: '1em',
                                        fontSize: '0.15em',
                                    }}
                                    className={` ${status === "Ready to burn" ? 'text-green-500' : "text-yellow-300"}`}
                                >{status}</p>
                            </div>
                            <div className="flex flex-col items-center">

                                <div

                                    style={{
                                        minWidth: '1em',
                                        width: '100vw',
                                        maxWidth: ` 1.5em `,
                                        maxHeight: '17vh',
                                        height: "100vh",
                                        padding: '0.1em'
                                    }}

                                    className={`rounded-xl overflow-y-scroll scrollbar-none
border-2 border-white border-opacity-50  flex flex-wrap content-start`} // Add the grid class here
                                >
                                    {renderResultBoxes(status)}


                                </div>
                                <button
                                    onClick={() => {
                                        openGuide()

                                    }
                                    }
                                    style={{
                                        color: "#2E3192",
                                        backgroundColor: "white",
                                        fontSize: "0.75rem",
                                    }}
                                    className=" px-2 py-1  rounded-lg mt-2"
                                >Guide</button>

                            </div>
                        </div>


                    </div>

                </div>}

                {phase === 3 && <div className="flex flex-row justify-center space-x-20">
                    {/* // isApproved, getApproval, fetchEmail, setLists */}

                    <Phase3 missingPalettes={missingPalettes} nfts={lists}
                        status={status}
                        isApproved={isApproved}
                        getApproval={getApproval}
                        fetchEmail={fetchEmail}
                        setLists={setLists}

                    />

                </div>}

                <div>
                    <p
                        style={{
                            fontSize: '0.15em',
                            // color: '#2E3192'
                        }}
                        className="m-0 "
                    >{gas ? `GAS : ${gas?.count ? gas.count.toFixed() : "?"} GWEI` : ''}</p>
                </div>
                {phase <= 2 && <button

                    onClick={() => {
                        if (isApproved) {
                            if (status === "Ready to burn") {
                                if (phase === 1) {
                                    burn()
                                }
                                else if (phase === 2) {
                                    fetchEmail()
                                }
                            } else {
                                alert(status)
                            }
                        } else {
                            getApproval()
                        }
                    }}

                    style={{
                        fontSize: '0.35em',
                        color: '#2E3192'
                    }}
                    className=" bg-white rounded-xl px-3"
                >
                    {isApproved ? 'CONFIRM BURN' : "APPROVE"}
                </button>}


            </div>

        </DndProvider>
    );
}


export default Tokens