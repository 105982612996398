import React, { useState, useEffect } from 'react';

const ImageWithLoad = ({ src }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsImageLoaded(true);
    };

    return (
        <div className={'relative w-full h-full'}>
            <img
                src={src}
                onLoad={handleImageLoad}
                alt={'crowd'}
                className={'absolute top-0 left-0  w-full h-full z-20'}
            />
            {!isImageLoaded && (
                <div className='absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center'>
                    <div className='loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8' />
                </div>
            )}
        </div>
    );
};

function ImageStack({ images, setPreviewImages }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState(null);
    const [animationClass, setAnimationClass] = useState('');

    const handleNext = () => {
        let newIndex = (currentIndex + 1) % images.length;
        setNextIndex(newIndex);
        setAnimationClass('animate-out-right');
    };

    const handlePrevious = () => {
        let newIndex = (currentIndex - 1 + images.length) % images.length;
        setNextIndex(newIndex);
        setAnimationClass('animate-out-left');
    };

    // Add this useEffect to handle state updates after the animation ends
    useEffect(() => {
        if (nextIndex !== null && animationClass !== '') {
            const timer = setTimeout(() => {
                setCurrentIndex(nextIndex);
                setNextIndex(null);
                setAnimationClass('');
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [nextIndex, animationClass]);

    const generateRotation = (index) => {
        const baseAngle = (index % 2 === 0 ? -1 : 1) * (Math.random() * 3 + 1);
        return `rotate-${Math.round(baseAngle)} transform`;
    };


    return (
        <div

            className=" flex flex-col justify-center items-center text-center">
            <div className='flex items-center'>
                {images.length > 1 && (
                    <button className='p-2 mr-5' onClick={handlePrevious}>
                        {'<'}
                    </button>
                )}

                <div className='relative w-44 h-44 md:w-80 md:h-80'>
                    {images.map((image, index) => {
                        let url = image?.metadata?.image;

                        let phase = 0;

                        let phaseAttribute = image?.metadata.attributes.find(
                            (attribute) => {
                                return attribute.trait_type === 'EVOLUTION';
                            }
                        );
                        
                        if (phaseAttribute?.value === 'Grid') {
                            phase = 3;
                        } else if (phaseAttribute?.value === 'Reach') {
                            phase = 2;
                        } else {
                            phase = 1;
                        }

                        if (!phaseAttribute) {
                            url = `https://bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi.ipfs.dweb.link/${image.id}.png`;
                        }

                        return (
                            <div
                                key={index}
                                className={`absolute w-full h-full border border-gray-300 shadow-md transition-all duration-500 ${index === currentIndex
                                    ? ` z-20 ${animationClass}`
                                    : index === nextIndex
                                        ? `z-10 ${generateRotation(index)}`
                                        : ` z ${generateRotation(index)}`
                                    }`}
                            >
                                <p
                                    style={{
                                        fontSize: '0.12em',
                                        background: '#2E3192',
                                    }}
                                    className='text-center text-white z-30 absolute bottom-0 right-0  px-1 rounded-tl-lg'
                                >
                                    {image.id}
                                </p>

                                {phase > 0 && (
                                    <p
                                        style={{
                                            fontSize: '0.12em',
                                            background: '#FBD34C',
                                            color: '#2E3192',
                                        }}
                                        className='text-center text-white z-30 absolute top-0 left-0  px-1 rounded-br-lg'
                                    >
                                        {phase}
                                    </p>
                                )}
                                <ImageWithLoad src={url} />
                                {/* <img
                key={image + index}
                src={url}
                alt={`newPFP`}

              /> */}
                            </div>
                        );
                    })}
                </div>
                {images.length > 1 && (
                    <button className='p-2 ml-5' onClick={handleNext}>
                        {'>'}
                    </button>
                )}
                <style>{`
        .animate-out-left {
          animation: moveOutLeft 0.5s ease-in-out forwards;
        }

        .animate-out-right {
          animation: moveOutRight 0.5s ease-in-out forwards;
        }

        @keyframes moveOutLeft {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }

        @keyframes moveOutRight {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(100%);
          }
        }
      `}</style>
            </div>
            <div
                style={{
                    fontSize: "0.3em",
                }}
                className="flex flex-col justify-center items-center text-center space-y-3 mt-5">
{/* Find the evolution attribute */}

                <p>YOU'VE CREATED A {images[currentIndex]?.metadata?.attributes.find((attribute) => attribute.trait_type === 'EVOLUTION')?.value} </p>

                <a
                    target='_blank'
                    href={`https://opensea.io/assets/ethereum/0xaa7200ee500de2dcde75e996de83cbd73bca9705/${images[currentIndex]?.id}`}
                    rel="noreferrer"
                    style={{
                        color: '#2E3192',
                        backgroundColor: 'white'
                    }}
                    className="rounded-xl px-2"
                >VIEW ON OPENSEA</a>
                <button
                    onClick={() => {
                        setPreviewImages([])
                    }}
                    style={{
                        color: '#2E3192',
                        backgroundColor: 'white'
                    }}
                    className="rounded-xl px-2"
                >START AGAIN</button>
            </div>
        </div>
    );
}

export default ImageStack;
