import {
    EthereumClient,
    walletConnectProvider,
    w3mConnectors, w3mProvider
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet } from 'wagmi/chains'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { config } from "../config";

import logo from '../assets/crowdLogo.svg'

const chains = [mainnet];
const walletConnectProjectID = config.walletConnectProjectID

// Wagmi client
const { provider } = configureChains(chains, [
    w3mProvider({ projectId: walletConnectProjectID }),
]);
const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ projectId: walletConnectProjectID, version: 1, chains }),
    provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);


const WalletConnectWrapper = (props) => {

    const { children } = props

    return (
        <>
            <WagmiConfig client={wagmiClient}>
                {children}
            </WagmiConfig>
            <Web3Modal
                projectId={walletConnectProjectID}
                ethereumClient={ethereumClient}
                themeVariables={{
                    '--w3m-font-family': 'pixel',
                    '--w3m-accent-color': 'white',
                    '--w3m-accent-fill-color': '#2E3192',
                    '--w3m-background-color': '#2E3192',
                    '--w3m-logo-image-url': logo,
                    '--w3m-text-medium-regular-line-height': '20px',
                    '--w3m-text-medium-regular-size': '1.75em',
                    '--w3m-text-medium-regular-letter-spacing': '0.25em',

                }}
            />
        </>
    );
}

export default WalletConnectWrapper;
