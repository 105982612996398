import {create} from 'zustand'

export const useUserStore = create((set) => ({
  user: {
    loggedIn: false
  },
  signOut: () => set(() => {
    localStorage.setItem("token", "")
    return {
      user: {
        loggedIn: false
      }
    }
  }),
  setUser: (user) => set((state) => ({ user })),
}))