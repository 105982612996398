import { useEffect, useState } from "react";
import Phase3Dropdown from "./Phase3Dropdown";
import check from "../assets/check.svg"
const paletteNames = [
    'Blueberry', 'Tropical', 'Coral Reef', 'Y2K', 'CMYK',
    'Bumblebee', 'Trix Yogurt', 'Rainbow', 'Vibrant', 'Easter Sunday',
    'Greyscale', 'Berry Blend', 'Neopolitan', 'Hot and Cold', 'Baby Shower',
    'Forest', 'Earth', 'Watermelon', 'Greeney Grey', '80\'s Mall',
    'Valentine\'s Day', 'Splash', 'Americana', 'Embers', 'Art Deco',
    'Vanilla Caramel', 'Purple Haze', 'Boot Camp', 'Dragonfruit', 'Apple Cider',
    'Farmer\'s Wheat', 'Primaries'
];

// isApproved, getApproval, fetchEmail, setLists
const Phase3 = ({ missingPalettes, nfts, status, isApproved, getApproval, fetchEmail, setLists, phase }) => {


    const [paletteChoices, setPaletteChoices] = useState({})

    const [selectedPalette, setSelectedPalette] = useState('Blueberry')
    const [allNftsSorted, setAllNftsSorted] = useState({})
    const [upgradedId, setUpgradedId] = useState(0)

    const getlistsFromChoices = (choices, upgradePalette) => {
        // gather all ids
        console.log("Updating picks")

        let allClaimedIds = []
        let upgradeIds = []

        for (let i = 0; i < paletteNames.length; i++) {
            const palette = paletteNames[i];
            let id = choices[palette]?.id
            if (palette === upgradePalette) {
                upgradeIds.push(id)
            } else {
                allClaimedIds.push(id)
            }
        }


        // get a list of all nfts
        let allNfts = []
        allNfts = allNfts.concat(nfts?.wallet)
        allNfts = allNfts.concat(nfts?.burn)
        allNfts = allNfts.concat(nfts?.evolve)

        let wallet = []
        let burn = []
        let evolve = []

        for (let i = 0; i < allNfts.length; i++) {
            const nft = allNfts[i];
            if (allClaimedIds.includes(nft.id)) {
                burn.push(nft)
            } else if (upgradeIds.includes(nft.id)) {
                evolve.push(nft)
            } else {
                wallet.push(nft)
            }
        }

        return {
            wallet: wallet,
            burn: burn,
            evolve: evolve
        }
    }

    useEffect(() => {
        let allNfts = []
        allNfts = allNfts.concat(nfts?.wallet)
        allNfts = allNfts.concat(nfts?.burn)
        allNfts = allNfts.concat(nfts?.evolve)


        let newPalletteChoices = {}
        let newSortedNfts = {}

        for (let i = 0; i < allNfts.length; i++) {
            const nft = allNfts[i];
            const palette = nft?.metadata?.attributes?.find((attribute) => {
                return attribute.trait_type === 'PALETTE'
            })?.value
            const isEvolved = nft?.metadata?.attributes?.find((attribute) => {
                return attribute.trait_type === 'EVOLUTION'
            }) !== undefined

            let tokenObject = {
                id: nft.id,
                palette: palette,
                img: nft?.metadata?.image,
                isEvolved: isEvolved,
                metadata: nft?.metadata
            }

            if (palette) {
                if (!newPalletteChoices[palette]) {
                    newPalletteChoices[palette] = tokenObject

                    newSortedNfts[palette] = [nft.id]


                } else {
                    if (!isEvolved && newPalletteChoices[palette].isEvolved) {
                        newPalletteChoices[palette] = tokenObject
                    }

                    newSortedNfts[palette].push(nft.id)
                }
            }

        }

        setAllNftsSorted(newSortedNfts)

        setPaletteChoices(newPalletteChoices)

        if (missingPalettes.length === 0) {
            setUpgradedId('Blueberry')

            let newLists = getlistsFromChoices(newPalletteChoices, "Blueberry")
            setLists(newLists)

        }

    }, [missingPalettes])

    const changeSelectedIdForPalette = (palette, id) => {
        let newPaletteChoices = { ...paletteChoices }

        let allNfts = []
        allNfts = allNfts.concat(nfts?.wallet)
        allNfts = allNfts.concat(nfts?.burn)
        allNfts = allNfts.concat(nfts?.evolve)

        let token = allNfts.find((nft) => {
            return nft.id === id
        })

        let tokenObj = {
            id: token.id,
            palette: palette,
            img: token?.metadata?.image,
            isEvolved: token?.metadata?.attributes?.find((attribute) => {
                return attribute.trait_type === 'EVOLUTION'
            }) !== undefined,
        }

        newPaletteChoices[palette] = tokenObj

        setPaletteChoices(newPaletteChoices)

        let newLists = getlistsFromChoices(newPaletteChoices, selectedPalette)
        setLists(newLists)

    }

    return <div>
        <div className="flex flex-col items-center">

            <div className="flex flex-row items-center space-x-5">
                <p
                    style={{
                        lineHeight: '0.9em',
                        fontSize: '0.5em',
                    }}
                    className="text-white mt-5"
                >
                    SELECT YOUR GRID
                </p>
                <div
                    style={{
                        lineHeight: '0.5em',
                        fontSize: '0.2em',
                    }}
                    className="mb-3"
                >
                    <p
                        style={{
                            fontSize: '0.75em',
                        }}
                        className="mt-5 text-center w-full">STATUS</p>
                    <div className="border-2 
    p-3
    border-white"
                        style={{
                            fontSize: '0.75em',
                        }}
                    >
                        <p
                            className={` ${status === "Ready to burn" ? 'text-green-500' : 'text-red-500'}`}
                        >{status}</p>


                    </div>
                </div>

            </div>



            <div className={` mb-5`}
                style={{
                    // height: '60vh',
                }}
            >

                <div className="flex flex-col sm:flex-row sm:space-x-3 justify-center items-start">
                    <div
                        style={{
                            width: '6em',
                        }}
                        className="flex flex-wrap justify-center">
                        {paletteNames.map((palette) => {

                            let url;
                            if (paletteChoices[palette]) {
                                if (!paletteChoices[palette].isEvolved) {
                                    // url = `https://ipfs.io/ipfs/bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi/${paletteChoices[palette].id}.png`
                                    url = `https://bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi.ipfs.dweb.link/${paletteChoices[palette].id}.png`
                                } else {
                                    url = paletteChoices[palette].img
                                }
                            }
                            return <div
                                onClick={() => {
                                    if (paletteChoices[palette]) {
                                        setSelectedPalette(palette)
                                    }
                                }}

                                className={`
                                border-2 border-white
                         ${paletteChoices[palette] ? (missingPalettes.length === 0 && selectedPalette === palette ? ' border-yellow-500 ' : ' hover:border-yellow-500') : ''}

                         cursor-pointer
                        `}>

                                {paletteChoices[palette] ?
                                    <div className="relative">
                                        {paletteChoices[palette].isEvolved && <p
                                            style={{
                                                fontSize: '0.1em',
                                                color: '#2E3192'
                                            }}
                                            className="absolute top-0 left-0 bg-yellow-500 text-white rounded-br-lg px-1"
                                        >^</p>}
                                        <img src={url}
                                            style={{
                                                height: '0.8em',
                                                width: '0.8em',
                                            }}
                                            alt="imageForCrowd"
                                            onError={(e) => {
                                                console.log("CHANGING BC ERROR")
                                                console.log(e.target.src)
                                                e.target.onerror = null; // Prevents infinite fallback loop
                                                if (e.target.src.includes("dweb")) {
                                                    e.target.src = `https://ipfs.io/ipfs/bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi/${paletteChoices[palette].id}.png`
                                                } else {
                                                    e.target.src = `https://bafybeibgoz4ugc63aiaphs4hcxclzvrdgvqcyj2hapvbj5yhgrvok7caqi.ipfs.dweb.link/${paletteChoices[palette].id}.png`
                                                }
                                            }}

                                        />

                                        {
                                            palette === upgradedId && <div className=" bg-white  rounded-lg absolute top-0 right-0">
                                                <img src={check} alt='check'
                                                    style={{
                                                        height: '0.15em',
                                                    }}
                                                />
                                            </div>


                                        }

                                        <p
                                            style={{
                                                fontSize: '0.1em',
                                                color: '#2E3192'
                                            }}
                                            className="absolute bottom-0 right-0 bg-white rounded-tl-lg pl-1"
                                        >{paletteChoices[palette].id}</p>

                                    </div> : <div>
                                        <div
                                            style={{
                                                height: '0.8em',
                                                width: '0.8em',
                                            }}
                                            className="border-white border-2 flex justify-center items-center"
                                        >
                                            <p
                                                style={{
                                                    fontSize: '0.5em',
                                                }}
                                                className="mt-2 text-red-500"
                                            >X</p>
                                        </div>
                                    </div>}


                            </div>
                        })
                        }
                        <div className="border-white border-2">
                            <div
                                style={{
                                    height: '0.8em',
                                    width: '2.48em',
                                }}
                                className=" flex justify-center items-center"
                            >
                                <button
                                    // isApproved, getApproval, fetchEmail,
                                    onClick={() => {
                                        if (isApproved) {
                                            if (status === "Ready to burn") {
                                                fetchEmail()
                                            } else {
                                                alert(status)
                                            }
                                        } else {
                                            getApproval()
                                        }
                                    }}

                                    style={{
                                        fontSize: '0.3em',
                                    }}
                                    className={` text-white pt-1
                                        ${status === "Ready to burn" ? 'opacity-100' : 'opacity-50'}
                                    
                                    `}
                                >
                                    {isApproved ? 'MINT GRID' : "APPROVE"}
                                </button>
                            </div>
                        </div>

                    </div>

                    {missingPalettes.length > 0 ? <div
                        className="w-full sm:w-auto"
                        style={{
                            fontSize: '0.3em',
                        }}
                    >
                        <p className="text-center sm:text-left">Missing Palettes</p>
                        <div className="border-2 
                        overflow-y-scroll
                        scrollbar-thin scrollbar-thumb-white scrollbar-track-transparent
                        
                        border-white"
                            style={{
                                height: '17.5em',
                                fontSize: '0.75em',
                            }}
                        >
                            {missingPalettes.map((palette) => {
                                return <div>
                                    <p>{palette}</p>
                                </div>
                            })}
                        </div>

                    </div> : <></>}
                </div>

                {/* <div
                        style={{
                            fontSize: '0.25em',
                        }}
                        className="flex flex-col justify-center items-center"
                    >
                        <p>LEGEND</p>
                        <div className="border-2 
                        p-5
                        
                        border-white"
                            style={{
                                fontSize: '0.75em',
                            }}
                        >
                            <div className="flex flex-row justify-between items-center space-x-5">
                                <p>TOKEN ID</p>


                                <p
                                    style={{
                                        color: '#2E3192'
                                    }}
                                    className=" bg-white rounded-lg pl-1 "
                                >XXX</p>

                            </div>

                            <div className="flex flex-row justify-between space-x-5 items-center">
                                <p>EVOLVED CROWD</p>

                                <p
                                    style={{
                                        color: '#2E3192'
                                    }}
                                    className=" bg-yellow-500 rounded-lg px-1"
                                >^</p>

                            </div>

                            <div className="flex flex-row justify-between space-x-5 items-center">

                                <p>ID TO UPGRADE</p>

                                <div className=" bg-white  rounded-lg flex flex-col justify-center items-center ">
                                    <img src={check} alt='check'
                                        style={{
                                            height: '1em',
                                        }}
                                    />
                                </div>
                            </div>



                        </div>

                        <p className="mt-5">STATUS</p>
                        <div className="border-2 
                        p-5
                        
                        border-white"
                            style={{
                                fontSize: '0.75em',
                            }}
                        >
                            <p>{status}</p>


                        </div>

                    </div> */}

                {missingPalettes.length === 0 && <div className="flex flex-row justify-center space-x-2 my-2">
                    <div>
                        <Phase3Dropdown options={paletteNames} current={selectedPalette} setCurrent={setSelectedPalette} direction={'up'} />
                    </div>

                    <div>
                        <Phase3Dropdown options={allNftsSorted[selectedPalette] || []} current={paletteChoices[selectedPalette]?.id} center={true} setCurrent={(newId) => changeSelectedIdForPalette(selectedPalette, newId)} direction={'up'} />
                    </div>

                    {/* Create a checkbox */}
                    <div className="flex flex-col items-center justify-center">

                        <button
                            style={{
                                width: '0.45em',
                                height: '0.45em',
                            }}
                            className=" border-2 border-white rounded-md"
                            onClick={() => {
                                setUpgradedId(selectedPalette)
                                let newLists = getlistsFromChoices(paletteChoices, selectedPalette)
                                setLists(newLists)
                            }}
                        >
                            {upgradedId === selectedPalette && <p
                                style={{
                                    fontSize: '0.35em',
                                }}
                            >X</p>}
                        </button>
                        <p
                            style={{
                                fontSize: '0.1em',
                            }}
                        >UPGRADED ID</p>
                    </div>


                </div>}


            </div>

        </div>
    </div>
}

export default Phase3