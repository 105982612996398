import { useRef } from "react";
import { useOutsideAlerter } from "../../helpers/OutsideAlerter";

const Stats = ({ stats, close }) => {

    let ref = useRef(null);

    useOutsideAlerter(ref, close)


    return <div className='fixed top-0 left-0 w-screen flex flex-col justify-center items-center bg-opacity-75 text-white min-h-screen bg-white  z-50'>
        {/* DESKTOP */}
        <div className='flex-col justify-center hidden sm:flex '>
            <div
                ref={ref}
                style={{
                    minWidth: "500px",
                    width: "100%",
                    border: '0.1em solid #2E3192',
                }}

                className=" w-full bg-white"
            >
                <div
                    style={{
                        fontSize: '3rem',
                        borderBottom: '0.2em solid #2E3192',
                    }}
                    className="flex flex-row">
                    <div
                        style={{
                            borderRight: '0.2em solid #2E3192',
                        }}
                        className="px-5 group"
                    >
                        <button
                            onClick={() => {
                                close()
                            }
                            }
                            style={{
                                lineHeight: "1.8em",
                                color: '#2E3192'
                            }}
                            className=" group-hover:text-red-500 hover:text-red-500"
                        >
                            X
                        </button>
                    </div>
                    <p
                            style={{
                                lineHeight: "1.8em",
                                color: '#2E3192'
                            }}
                        >STATS</p>
                </div>

                <div
                    style={{
                        fontSize: '1.75rem',
                        color: '#2E3192',
                        lineHeight: "1.25em",
                    }}
                    className="flex flex-row">
                    <div
                        style={{
                            borderRight: '0.4em solid #2E3192',
                        }}
                        className="px-5 flex py-5 flex-col w-2/3 text-right whitespace-nowrap"
                    >
                        <p>Crowds:</p>
                        <p>Evolved:</p>
                        <p>Burned:</p>

                        <p>Pod:</p>
                        <p>Herd:</p>
                        <p>Swarm:</p>
                        <p>Mob:</p>
                        <p>Colony:</p>
                        <p>Reach:</p>
                        <p>Grid:</p>



                    </div>
                    <div className="w-1/3 text-left pl-5 py-5">
                        <p>{stats?.totalCrowds?.count}</p>
                        <p>{stats?.evolvedCrowds?.count}</p>
                        <p>{stats?.burnedCrowds?.count}</p>

                        <p>{stats?.pod?.count}</p>
                        <p>{stats?.herd?.count}</p>
                        <p>{stats?.swarm?.count}</p>
                        <p>{stats?.mob?.count}</p>
                        <p>{stats?.colony?.count}</p>
                        <p>{stats?.reach?.count}</p>
                        <p>{stats?.grid?.count}</p>
                    </div>
                </div>
            </div>
        </div>

        {/* MOBILE */}
        <div className='flex flex-col justify-center sm:hidden w-full'>
            <div
                ref={ref}
                style={{
                    width: "100%",
                    border: '0.1em solid #2E3192',
                }}

                className=" w-full bg-white"
            >
                <div
                    style={{
                        fontSize: '3rem',
                        borderBottom: '0.2em solid #2E3192',
                    }}
                    className="flex flex-row ">
                    <div
                        style={{
                            borderRight: '0.2em solid #2E3192',
                        }}
                        className="px-5 group"
                    >
                        <button
                            onClick={() => {
                                close()
                            }
                            }
                            style={{
                                lineHeight: "1.8em",
                                color: '#2E3192'
                            }}
                            className=" group-hover:text-red-500 hover:text-red-500"
                        >
                            X
                        </button>
                    </div>
                    <div className="w-full flex flex-row justify-center">
                        <p
                            style={{
                                lineHeight: "1.8em",
                                color: '#2E3192'
                            }}
                        >STATS</p>
                    </div>
                </div>

                <div
                    style={{
                        fontSize: '1.75rem',
                        color: '#2E3192',
                        lineHeight: "1.25em",
                    }}
                    className="flex flex-row">
                    <div
                        style={{
                            borderRight: '0.4em solid #2E3192',
                        }}
                        className="px-5 flex py-5 flex-col w-2/3 text-right whitespace-nowrap"
                    >
                        <p>Crowds:</p>
                        <p>Evolved:</p>
                        <p>Burned:</p>

                        <p>Pod:</p>
                        <p>Herd:</p>
                        <p>Swarm:</p>
                        <p>Mob:</p>
                        <p>Colony:</p>
                        <p>Reach:</p>
                        <p>Grid:</p>



                    </div>
                    <div className="w-1/3 text-left pl-5 py-5">
                        <p>{stats?.totalCrowds?.count}</p>
                        <p>{stats?.evolvedCrowds?.count}</p>
                        <p>{stats?.burnedCrowds?.count}</p>

                        <p>{stats?.pod?.count}</p>
                        <p>{stats?.herd?.count}</p>
                        <p>{stats?.swarm?.count}</p>
                        <p>{stats?.mob?.count}</p>
                        <p>{stats?.colony?.count}</p>
                        <p>{stats?.reach?.count}</p>
                        <p>{stats?.grid?.count}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Stats;