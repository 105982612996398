
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Layout from './layout/Layout';
import WalletConnectWrapper from './layout/WalletConnectWrapper';

function App() {
  return (
    <div className="App">
      <WalletConnectWrapper>
        <Router>
          <Layout />
        </Router>
      </WalletConnectWrapper>
    </div>
  );
}

export default App;
