import React, { useRef, useState } from 'react';
import { useOutsideAlerter } from '../helpers/OutsideAlerter';

import check from "../assets/check.svg"

const Dropdown = ({ paletteInfo, palette, setPalette, direction }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const divRef = useRef(null);

    useOutsideAlerter(divRef, () => {
        setShowDropdown(false);
    });

    const dropdownContentStyle = {
        display: showDropdown ? 'block' : 'none',
        maxHeight: '400px',
        position: 'absolute',
        zIndex: 50,
        backgroundColor: '#2E3192',
    }

    if (direction === 'up') {
        dropdownContentStyle.bottom = '100%';
    } else {
        dropdownContentStyle.top = '100%';
    }

    const dropdownItemStyle = {
        cursor: 'pointer'
    }

    const RowDetails = ({ palette, fullSet }) => {
        return <div className='flex flex-row justify-between px-3 py-1'>
            <p>{palette}</p>
            {palette !== "All" && (fullSet ? <img src={check}
            style={{
                width: '1em',
            }}
            alt="check" /> : <p className='text-red-500'>X</p>)}
        </div>

    }

    return (
        <div
            ref={divRef}
            style={{
                fontSize: '0.25em',
                width: '10em',
            }}
            className="w-full flex flex-row justify-center relative"
        >
            <button
                onClick={toggleDropdown}

                className='border-2 border-white rounded-xl text-white-500 w-full'
            >
                {paletteInfo && palette && <RowDetails palette={palette} fullSet={paletteInfo[palette]?.hasFullSet} />}

            </button>
            <div className=" border-r-2 border-l-2 border-t-2 border-white overflow-y-scroll scrollbar-none w-full" style={dropdownContentStyle}>
                {paletteInfo && Object.keys(paletteInfo).map((p, index) => {
                    return (
                        <div
                            key={index}
                            style={dropdownItemStyle}
                            onClick={() => {
                                setPalette(p);
                                setShowDropdown(false);
                            }}
                            className='border-b-2 '
                        >
                            <RowDetails palette={p} fullSet={paletteInfo[p].hasFullSet} />
                        </div>
                    );
                })
                }
            </div>
        </div>
    );
};

export default Dropdown;