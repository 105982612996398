import { useRef, useState } from "react";
import { useOutsideAlerter } from "../../helpers/OutsideAlerter";

const Email = ({ close, burn }) => {


    const [email, setEmail] = useState('')



    return <div className='fixed top-0 left-0 w-screen flex flex-col justify-center items-center bg-opacity-75 text-white min-h-screen bg-white  z-50'>
        {/* DESKTOP */}
        <div className='flex-col justify-center hidden sm:flex '>
            <div
                style={{
                    minWidth: "500px",
                    width: "100%",
                    border: '0.1em solid #2E3192',
                }}

                className=" w-full bg-white px-5"
            >
                <div
                    className="bg-white flex mt-3 justify-center flex-col items-center"
                >
                    <p
                        style={{
                            fontSize: '0.3em',
                            color: '#2E3192',
                        }}
                    >Physical Artwork Claim </p>
                    <p
                        style={{
                            fontSize: '0.2em',
                            color: '#2E3192',
                        }}
                    >We will contact you soon for shipping details</p>
                </div>
                <div
                    className="bg-white flex mt-3 justify-center space-x-3 flex-row items-center"
                >

                    <input
                        type="text"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        placeholder="Enter your email"
                        className="text-center rounded-lg"
                        style={{
                            fontSize: '2rem',
                            lineHeight: "1.5em",
                            color: '#2E3192',
                            border: '0.1em solid #2E3192',
                        }}
                    />
                </div>

                <div
                    className="bg-white my-3 flex justify-center space-x-3 flex-row items-center"
                >
                    <button

                        onClick={() => {

                            close()

                        }}

                        style={{
                            fontSize: '0.35em',
                            color: 'red',
                            border: '0.1em solid red',
                        }}
                        className=" bg-white rounded-xl px-3"
                    >
                        EXIT
                    </button>
                    <button

                        onClick={() => {

                            burn(email)
                            close()
                        }}

                        style={{
                            fontSize: '0.35em',
                            color: '#2E3192',
                            border: '0.1em solid #2E3192',
                        }}
                        className=" bg-white rounded-xl px-3"
                    >
                        CONFIRM BURN
                    </button>
                </div>

            </div>
        </div>

        {/* MOBILE */}
        <div className='flex flex-col justify-center sm:hidden w-full'>
            <div
                style={{
                    width: "100%",
                    border: '0.1em solid #2E3192',
                }}

                className=" w-full bg-white px-5"
            >
                <div
                    className="bg-white flex mt-3 justify-center space-x-3 flex-row items-center"
                >

                    <input
                        type="text"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        placeholder="Enter your email"
                        className="text-center rounded-lg"
                        style={{
                            fontSize: '2rem',
                            lineHeight: "1.5em",
                            color: '#2E3192',
                            border: '0.1em solid #2E3192',
                        }}
                    />
                </div>

                <div
                    className="bg-white my-3 flex justify-center space-x-3 flex-row items-center"
                >
                    <button

                        onClick={() => {

                            close()

                        }}

                        style={{
                            fontSize: '0.35em',
                            color: 'red',
                            border: '0.1em solid red',
                        }}
                        className=" bg-white rounded-xl px-3"
                    >
                        EXIT
                    </button>
                    <button

                        onClick={() => {

                            burn(email)
                            close()

                        }}

                        style={{
                            fontSize: '0.35em',
                            color: '#2E3192',
                            border: '0.1em solid #2E3192',
                        }}
                        className=" bg-white rounded-xl px-3"
                    >
                        CONFIRM BURN
                    </button>
                </div>


            </div>
        </div>
    </div>
}

export default Email;