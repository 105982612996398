import React, { useEffect, useRef, useState } from 'react';
import test from "./1620-phase2.png";

const imageSize = 500;

const Test = () => {
    const canvasRef = useRef(null);
    const [hoveredColor, setHoveredColor] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [imageData, setImageData] = useState(null);

    const [reset, setReset] = useState(false);

    const colors = [
        "363d37",
        "c8cec6",
        "8b938a",
        "758275",
        "283026",
        "424c44",
        "1e3012",
        "566852",
    ];

    useEffect(() => {
        console.log("Building Canvas")
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        let img = new Image();

        img.onload = () => {
            canvas.width = imageSize;
            canvas.height = imageSize;
            context.drawImage(img, 0, 0, imageSize, imageSize);
            setImageData(context.getImageData(0, 0, imageSize, imageSize));
        };
        img.src = test;

        const handleMouseMove = (e) => {
            const rect = canvas.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            const pixelData = context.getImageData(x, y, 1, 1).data;
            const rgb = Array.from(pixelData).slice(0, 3).map(v => v.toString(16).padStart(2, '0')).join('');
            setHoveredColor(rgb);
        };

        canvas.addEventListener('mousemove', handleMouseMove);

        return () => {
            canvas.removeEventListener('mousemove', handleMouseMove);
        };
    }, [reset]);

    useEffect(() => {
        if (!imageData) return;

        if (selectedColor === null) {
            setReset(!reset);
            return;
        }

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const newImageData = new ImageData(new Uint8ClampedArray(imageData.data), imageSize, imageSize);

        for (let i = 0; i < newImageData.data.length; i += 4) {
            const rgb = Array.from(newImageData.data.slice(i, i + 3)).map(v => v.toString(16).padStart(2, '0')).join('');
            if (rgb !== selectedColor) {
                newImageData.data[i + 3] = 0;
            }
        }

        context.putImageData(newImageData, 0, 0);
    }, [selectedColor]);

    console.log(selectedColor, reset)

    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    return (
        <div className="w-full h-screen flex flex-row justify-center items-center">
            <div
                className=''
            >
                <canvas
                    onMouseLeave={() => setHoveredColor(null)}
                    ref={canvasRef} />
            </div>
            <div
                style={{
                    height: `${imageSize}px`,
                    width: `120px`,
                }}
                className='flex flex-col justify-center items-center'
            >
                {colors.map((color) => {
                    const isHovered = color === hoveredColor;
                    return (
                        <div
                            style={{
                                backgroundColor: `#${color}`,
                            }}

                            onMouseEnter={() => handleColorClick(color)}
                            onMouseLeave={() => handleColorClick(null)}
                            className={`flex flex-row justify-center  items-center h-full w-full space-x-2 ${isHovered ? '  ' : ' '}`}
                        >
                            <p
                                style={{
                                }}
                                className={`text-black  ${isHovered ? 'text-lg font-bold opacity-50 text-yellow-500 ' : 'opacity-25'}`}>{color}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Test;
