import {create} from 'zustand'

export const useGlobalStateStore = create((set) => ({
  siteState: {
    players: [],
    isLoading: false
  },

//   setPlayers: (players) => set((state) => {
//     let newSiteState = { ...state.siteState }
//     newSiteState.players = players
//     return { siteState: newSiteState }
//   }),
  setLoading: (loadingState) => set((state) => {
    let newSiteState = { ...state.siteState }
    newSiteState.isLoading = loadingState
    return { siteState: newSiteState }
  }),

}))