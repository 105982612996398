import { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Landing from '../pages/Landing';
import { useGlobalStateStore } from '../stores/GlobalSiteStore';
import { useTransitionStore } from '../stores/TransitionStore';
import Burn from '../pages/Burn';
import NetworkWarning from './NetworkWarning';
import { useStatsStore } from '../stores/StatsStore';
import DataService from '../services/DataService';
import Test from '../pages/Test';

const Layout = (props) => {

  const { siteState } = useGlobalStateStore();
  const { isLoading } = siteState;

  const { stats, setStats } = useStatsStore();

  useEffect(() => {
    console.log("CROWDS BURN - PHASE 1");

    const fetchData = async () => {
      try {
        const res = await DataService.getStats();
        setStats(res.data.stats);
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchData(); // Fetch data initially

    const intervalId = setInterval(fetchData, 300000); // Run fetchData every 5 minutes (300,000 ms)

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, []);




  return <div className="min-h-screen h-full relative w-full overflow-x-hidden max-w-screen bg-site-dark">
    <NetworkWarning />
    {!stats ? <div className='fixed top-0 left-0 w-screen flex flex-col justify-center items-center bg-opacity-75 text-white min-h-screen bg-black  z-50'>
      <div className='flex flex-col justify-center items-center'>
        <p className='text-center'>LOADING</p>
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8" />
      </div>
    </div> : null}


    {isLoading ? <div className='fixed top-0 left-0 w-screen flex flex-col justify-center items-center bg-opacity-75 text-white min-h-screen bg-black  z-50'>
      <div className='flex flex-col justify-center items-center'>
        <p className='text-center'>LOADING</p>
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8" />
      </div>
    </div> : null}


    <Routes>
      <Route
        path="/"
        element={
          <Landing />
        }
      />

      <Route
        path="/test"
        element={
          <Test />
        }
      />

      <Route
        path="/burn"
        element={
          <Burn />
        }
      />
    </Routes>

  </ div>
}

export default Layout;
